import {
    Avatar,
    Box,
    Button,
    Drawer,
    IconButton,
    List,
    ListItem,
    Stack,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import logo from "/@/assets/air-aware-logo.svg";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import LanguageSelector from "../accessibility/LanguageSelector";
import {useTranslation} from "react-i18next";
import {motion} from "framer-motion";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setAppState} from "/@/store/slices/appStateSlice";
import {usePageContext} from "/@/renderer/usePageContext";
import {navigate} from 'vike/client/router'
import InfoIcon from "@mui/icons-material/Info";

const Sidebar = () => {
    const theme = useTheme();
    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const [offlineMode, setOfflineMode] = useState<boolean>(false);
    const mobile = useMediaQuery(theme.breakpoints.down("md"));
    const portrait = useMediaQuery("(orientation: portrait)");
    const isMobile = mobile || portrait;
    const pageContext = usePageContext();
    const dispatch = useDispatch();


    useEffect(() => {
        if (pageContext.urlPathname) {
            dispatch(setAppState(pageContext.urlPathname));
        }
    }, [dispatch, pageContext.urlPathname]);


    useEffect(() => {
        if (typeof navigator !== 'undefined') {
            setOfflineMode(!navigator.onLine);
        }
    }, []);

    const changeLanguage = useCallback((language: string) => {
        i18n.changeLanguage(language);
    }, [i18n]);

    const buttonVariants = {
        hover: {scale: 1.1},
        tap: {scale: 0.95},
    };

    return (
        <Box component={motion.div}>
            <Drawer
                variant="permanent"
                sx={{
                    width: sizeConfigs.sidebar.width,
                    flexShrink: 0,
                    display: {xs: "none", md: isMobile ? "none" : "block"},
                    "& .MuiDrawer-paper": {
                        overflow: "hidden",
                        width: sizeConfigs.sidebar.width,
                        boxSizing: "border-box",
                        borderRight: "0px",
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.secondary.light,
                    },
                }}
            >
                    <Toolbar sx={{marginBottom: "20px", justifyContent: "center"}}>
                        <Button
                            onClick={() => {
                                if (typeof window !== 'undefined') {
                                    navigate('/about')
                                }
                            }}
                            sx={{
                                padding: 0,
                                backgroundColor: "transparent",
                                color: theme.palette.secondary.light,
                                textTransform: "none",
                            }}
                            aria-label={'About Air Aware'} // Accessible name for the button
                        >
                            <motion.div variants={buttonVariants} animate="animate" aria-label={'Air Aware Logo'}>
                                <Stack
                                    aria-label={'Air Aware Logo'}
                                    sx={{width: "100%", pt: 2}}
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={1} // Adjust the spacing as needed
                                >
                                    <Avatar alt={'Air Aware Logo'} src={logo}/>
                                    <Box sx={{position: 'relative', display: 'inline-flex', alignItems: 'center'}}>
                                        <Typography variant="h6" component={'h1'} sx={{display: 'inline'}}>
                                            Air Aware
                                        </Typography>
                                        <Tooltip title={t('learn_about_air_aware')} placement="top" aria-label={t('learn_about_air_aware')}>
                                            <InfoIcon fontSize="small" sx={{
                                                color: 'secondary.light',
                                                position: 'absolute',
                                                bottom: 30,
                                                right: 0
                                            }}/>
                                        </Tooltip>
                                    </Box>
                                </Stack>
                            </motion.div>
                        </Button>
                    </Toolbar>
                <List disablePadding>
                    {appRoutes.map((route, index) =>
                        route.sidebarProps ? (
                            // <ListItem disablePadding>
                                <SidebarItem item={route} index={index} key={index}/>
                            // </ListItem>
                        ) : null
                    )}
                    <ListItem>
                        <LanguageSelector
                            currentLanguage={currentLanguage}
                            changeLanguage={changeLanguage}
                            isSideBar={true}/>
                    </ListItem>
                </List>
                {offlineMode && (
                    <Typography color="error" textAlign={"center"} mt={5}>
                        {t("offline_mode")}
                    </Typography>
                )}
            </Drawer>
        </Box>
    );
};

export default Sidebar;
