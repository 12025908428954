import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {supabaseClient} from "/@/renderer/PageShell";


export interface EventDate {
    start: string;
    end: string;
}
export interface EventType {
    id: number,
    created_at: string,
    title: string,
    description: string,
    image: string,
    start: string,
    end: string,
    council: string[]
    active?: boolean,
    additional_dates: EventDate[] | null;
}

export const fetchAllEvents = createAsyncThunk(
    'events/fetchAll',
    async (messageData: { "language": string }) => {
        const {data, error} = await supabaseClient.functions.invoke('get-events', {
            body: {language_code: messageData.language}
        })
        console.log(data);
        if (error) throw error;
        return data;
    }
);


export const getEventDetails = createAsyncThunk(
    'events/fetchDetails',
    async (messageData: {eventId: number, language: string}) => {
        const {data, error} = await supabaseClient.functions.invoke('get-event', {
            body: {id: messageData.eventId, language_code: messageData.language}
        });
        if (error) return "";
        return data.description;
    }
);


export const eventsSlice = createSlice({
    name: "events",
    initialState: {
        events: [] as EventType[],
        eventsStatus: 'idle' as "idle" | "loading" | "succeeded" | "failed",
        eventDescription: "",
        eventDescriptionStatus: 'idle',
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllEvents.pending, (state) => {
                state.eventsStatus = 'loading';
            })
            .addCase(fetchAllEvents.fulfilled, (state, action) => {
                state.eventsStatus = 'succeeded';
                state.events = action.payload;
            })
            .addCase(fetchAllEvents.rejected, (state, action) => {
                state.eventsStatus = 'failed';
            })
            .addCase(getEventDetails.pending, (state) => {
                state.eventDescriptionStatus = 'loading';
            })
            .addCase(getEventDetails.fulfilled, (state, action) => {
                state.eventDescription = action.payload;
                state.eventDescriptionStatus = 'succeeded';
            })
            .addCase(getEventDetails.rejected, (state) => {
                state.eventDescriptionStatus = 'failed';
            });
    },
});

export const {actions, reducer} = eventsSlice;

export default eventsSlice.reducer;