import React from 'react';
import {SxProps, Theme, useTheme} from "@mui/material/styles";
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import selectArrowIcon from "/@/assets/select-arrow-icon.svg";
import {
    fetchInfoHubCoverArticles,
    fetchInfoHubInfoGraphics,
    fetchInfoHubInfoVideos
} from "/@/store/slices/infoHubSlice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "/@/store";
import {visuallyHidden} from "@mui/utils";
import {fetchAllArticles} from "/@/store/slices/newsApiSlice";
import {fetchAllEvents} from "/@/store/slices/eventsSlice";

const languages = [
    {label: "العربية (Arabic)", value: "ar"},
    {label: "বাংলা (Bengali)", value: "bn"},
    {label: "简体中文 (Chinese Simplified)", value: "zh"},
    {label: "English", value: "en"},
    {label: "Français (French)", value: "fr"},
    {label: "Deutsch (German)", value: "de"},
    {label: "ગુજરાતી (Gujarati)", value: "gu"},
    {label: "हिंदी (Hindi)", value: "hi"},
    {label: "Italiano (Italian)", value: "it"},
    {label: "日本語 (Japanese)", value: "ja"},
    {label: "ਪੰਜਾਬੀ (Punjabi)", value: "pa"},
    {label: "Polski (Polish)", value: "pl"},
    {label: "Português (Portuguese)", value: "pt"},
    {label: "Soomaali (Somali)", value: "so"},
    {label: "Español (Spanish)", value: "es"},
    {label: "தமிழ் (Tamil)", value: "ta"},
    {label: "Türkçe (Turkish)", value: "tr"},
    {label: "اردو (Urdu)", value: "ur"}
];

type LanguageSelectorProps = {
    currentLanguage: string;
    changeLanguage: (language: string) => void;
    sx?: SxProps<Theme>;
    isSideBar: boolean;
};

const CustomDropDownIcon = (
    props: React.ImgHTMLAttributes<HTMLImageElement>
) => <img src={selectArrowIcon} alt="Select arrow" {...props} />;
const LanguageSelector: React.FC<LanguageSelectorProps> = ({
                                                               currentLanguage,
                                                               changeLanguage,
                                                               isSideBar,
                                                               sx,
                                                               ...rest
                                                           }) => {
    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();
    const handleLanguageChange = (event: SelectChangeEvent<string>) => {
        const newLanguage = event.target.value as string
        changeLanguage(newLanguage);
        dispatch(fetchInfoHubCoverArticles(newLanguage));
        dispatch(fetchInfoHubInfoGraphics(newLanguage));
        dispatch(fetchInfoHubInfoVideos(newLanguage));
        dispatch(fetchAllArticles({organisations: [], language: newLanguage}));
        dispatch(fetchAllEvents({language: newLanguage}))
    };

    return (
        <FormControl sx={{...sx, m: isSideBar ? 1 : undefined, mb: 2.5}}>
            <InputLabel style={visuallyHidden} id="language-selector-label">Language</InputLabel>
            <Select
                labelId="language-selector-label"
                id="language-selector"
                value={currentLanguage}
                onChange={handleLanguageChange}
                label="Language"
                autoWidth
                variant="outlined"
                sx={{
                    maxWidth: '115px', // Set to desired maximum width
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    fontWeight: 700,
                    color: theme.palette.secondary.light,
                    textAlign: "center",
                    marginRight: "auto",
                    borderRadius: "8px",
                    backgroundColor: theme.palette.primary.main,
                    "& .MuiSelect-select": {
                        padding: 1,
                        "&:focus": {
                            outline: "1px solid rgba(0, 0, 0, 0.10)",
                        },
                    },
                    "& .MuiInputBase-input": {
                        border: "1px solid rgba(0, 0, 0, 0.10)",
                    },
                    "& .MuiSelect-icon": {
                        top: "25%",
                    },
                }}
                IconComponent={(props) => <CustomDropDownIcon {...props} />}
            >
                {languages.map((language) => (
                    <MenuItem value={language.value} key={language.value}
                              sx={{fontSize: isSideBar ? '0.9rem' : '1rem'}}>
                        {language.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default LanguageSelector;