import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type appState = {
  appState: string;
  mapTourSeenOnScreens: boolean;
    infoHubTourSeenOnScreens: boolean;
    communityPageTourSeenOnScreens: boolean;
};

const initialState: appState = {
  appState: "",
  mapTourSeenOnScreens: false,
  infoHubTourSeenOnScreens: false,
  communityPageTourSeenOnScreens: false,
};

export const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setAppState: (state, action: PayloadAction<string>) => {
      state.appState = action.payload;
    },
    setMapTourSeen: (state, action: PayloadAction<boolean>) => {
      state.mapTourSeenOnScreens = action.payload;
    },
    setInfoHubTourSeen: (state, action: PayloadAction<boolean>) => {
      state.infoHubTourSeenOnScreens = action.payload;
    },
    setCommunityTourSeen: (state, action: PayloadAction<boolean>) => {
      state.communityPageTourSeenOnScreens = action.payload;
    }
  }
});

export const {
  setAppState,
    setMapTourSeen,
    setInfoHubTourSeen,
    setCommunityTourSeen
} = appStateSlice.actions;

export default appStateSlice.reducer;