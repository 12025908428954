import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Session } from '@supabase/supabase-js';

interface SupabaseState {
  session: Session | null;
}

const initialState: SupabaseState = {
  session: null
};

export const supabaseSlice = createSlice({
  name: 'supabase',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<Session | null>) => {
      state.session = action.payload;
    }
  }
});

export const { setSession } = supabaseSlice.actions;

export default supabaseSlice.reducer;