import React from 'react';
import {RouteType} from "./config";
import {PeopleOutline, Info, Map, Settings, QuestionAnswer} from "@mui/icons-material";


const appRoutes: RouteType[] = [
    {
        path: "/",
        state: "map",
        sidebarProps: {
            displayText: "map",
            icon: <Map/>,
        },
    },
    {
        state: "assistant",
        path: "/assistant",
        sidebarProps: {
            displayText: "assistant_button",
            icon: <QuestionAnswer/>,
        },
    },
    {
        path: "/infohub",
        state: "infohub",
        sidebarProps: {
            displayText: "infohub",
            icon: <Info/>,
        },
    },
    {
        path: "/community",
        state: "community",
        sidebarProps: {
            displayText: "community",
            icon: <PeopleOutline/>,
        },
    },
    {
        path: "/settings",
        state: "settings",
        sidebarProps: {
            displayText: "settings",
            icon: <Settings/>,
        },
    },
];

export default appRoutes;