import * as React from "react";
import type {Theme} from "@mui/material/styles";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import {useSelector} from "react-redux";
import {RootState} from "/@/store";
import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/400-italic.css";
import {useMediaQuery} from "@mui/material";

declare module "@mui/material/styles" {
    interface Palette {
        custom: Palette["primary"];
        chatAI: Palette["primary"];
    }

    interface PaletteOptions {
        custom: PaletteOptions["primary"];
        chatAI: PaletteOptions["primary"];
    }
}

interface ThemeContextType {
    theme: Theme | null;
    isColorBlindMode: boolean;
    toggleColorBlindMode: () => void;
    isMobile: boolean;
}

const ThemeContext = React.createContext<ThemeContextType>({
    theme: null,
    isColorBlindMode: false,
    toggleColorBlindMode: () => {
    },
    isMobile: false
});
export const useThemeContext = () => React.useContext(ThemeContext);

interface Props {
    children: React.ReactNode;
}

export const CustomThemeProvider: React.FC<Props> = (props) => {
    const fontSize = useSelector((state: RootState) => state.fontSize.size);
    const [isColorBlindMode, setIsColorBlindMode] = React.useState(false);

    // Use useEffect to safely access localStorage on the client side
    React.useEffect(() => {
        const storedIsColorBlindMode = localStorage.getItem("isColorBlindMode");
        if (storedIsColorBlindMode) {
            setIsColorBlindMode(JSON.parse(storedIsColorBlindMode));
        }
    }, []);

    React.useEffect(() => {
        localStorage.setItem("isColorBlindMode", JSON.stringify(isColorBlindMode));
    }, [isColorBlindMode]);


    const toggleColorBlindMode = () => {
        setIsColorBlindMode((prev) => !prev);
    };

    React.useEffect(() => {
        localStorage.setItem("isColorBlindMode", JSON.stringify(isColorBlindMode));
    }, [isColorBlindMode]);

    const returnMainTheme = (): Theme => {
        return createTheme({
            palette: {
                mode: "light",
                primary: {
                    main: "#ffffff",
                    light: "#F5F5F5",
                    contrastText: "#000000",
                },
                secondary: {
                    main: "#1a434d",
                    light: "#479696",
                },
                warning: {
                    main: "#FFB900",
                },
                success: {
                    main: "#FFCA28",
                    light: "#FF7900",
                },
                info: {
                    main: "#d3c2f7",
                },
                custom: {
                    main: "#99ff5e",
                    light: "#d8ed6d",
                    dark: "#86bef0",
                },
                chatAI: {
                    main: "#C3F44E",
                    light: "red",
                    dark: "#C91C1C",
                },
            },
            typography: {
                fontSize: fontSize,
                fontFamily: "Poppins",
            },
            components: {
                MuiCard: {
                    styleOverrides: {
                        root: {
                            // Default style
                            '&.understandingAirPollution': {
                                background: 'linear-gradient(180deg, #a2d5f2 0%, #e6e6e6 100%)',
                            },
                            '&.healthAndSocialImpact': {
                                background: 'linear-gradient(180deg, #98e2a0 0%, #b5c0a8 100%)',
                            },
                            '&.actionAndPolicy': {
                                background: 'linear-gradient(180deg, #d3c2f7 0%, #a094d3 100%)'
                            },
                        },
                    },
                },
            },
        });
    };

    const [theme, setTheme] = React.useState<Theme>(() => returnMainTheme());

    const returnColorBlindTheme = () => {
        return createTheme({
            palette: {
                mode: "light",
                primary: {
                    main: "#ffffff",
                    light: "#C4C4C4",
                    contrastText: "#0B0B0B",
                },
                secondary: {
                    main: "#3E6DA5",
                    light: "#569BDC",
                },
                warning: {
                    main: "#E6A817",
                },
                success: {
                    main: "#FFCA28",
                    light: "#000"
                },
                info: {
                    main: "#4F4A9B",
                },
                custom: {
                    main: "#deb610",
                    light: "#FFECB3",
                    dark: "#B8A657",
                },
                chatAI: {
                    main: "#B38F3E",
                    light: "#FFD700",
                    dark: "#8B6914",
                },
            },
            typography: {
                fontSize: fontSize,
                fontFamily: "Poppins",
            },
        });
    };

    React.useEffect(() => {
        if (isColorBlindMode) {
            setTheme(returnColorBlindTheme);
        } else {
            setTheme(returnMainTheme);
        }
    }, [fontSize, isColorBlindMode]);

    const [isMobile, setIsMobile] = React.useState(false);

// Set up media query listener
    React.useEffect(() => {
        const checkMediaQuery = () => {
            // Example media query: adjust as needed
            setIsMobile(window.matchMedia("(max-width: 768px)").matches);
        };
        checkMediaQuery(); // Initial check
        window.addEventListener('resize', checkMediaQuery);
        // Cleanup function
        return () => {
            window.removeEventListener('resize', checkMediaQuery);
        };
    }, []);

    const contextValue = {
        theme,
        isColorBlindMode,
        toggleColorBlindMode,
        isMobile
    };

    return (
        <ThemeProvider theme={theme}>
            <ThemeContext.Provider value={contextValue}>
                {props.children}
            </ThemeContext.Provider>
        </ThemeProvider>
    );
};
