import {Theme} from "@mui/material/styles";
import {Dayjs} from "dayjs";
import {ChartOptions, TimeUnit} from "chart.js";
import {GREEN, ORANGE, PollutantType, PURPLE, rangeColors} from "/@/constants/chartContants";
import {ChosenGuideline, Interval, PollutantDataEntry} from "/@/types/mapTypes";
import {defraPollutantRanges, whoPollutantRanges} from "/@/constants/mapConstants";

//
// export const createChartOptions = (chosenPollutant: PollutantType, graphData: PollutantDataEntry[], startDate: Dayjs, endDate: Dayjs, chosenGuideline: ChosenGuideline, title: string, theme: Theme ): ChartOptions => {
//     const ranges = chosenGuideline === 'WHO' ? whoPollutantRanges[chosenPollutant] : defraPollutantRanges[chosenPollutant];
//
//     // Calculate dynamic y-axis maximum
//     const allPollutantValues = graphData.map(entry => entry[Object.keys(entry)[0]]).filter(value => value !== null) as number[];
//     const maxPollutantValue = Math.max(...allPollutantValues);
//     // y max is either 100, or the max value + 10 rounded up to the nearest 10
//     const ymax = Math.max(100, Math.ceil((maxPollutantValue + 10) / 10) * 10);
//
//     const {unit, displayFormat} = getTimeScaleUnit(startDate, endDate);
//     return {
//         plugins: {
//             title: {
//                 display: true,
//                 text: title,
//                 font: {
//                     size: 18,
//                 }
//             },
//             annotation: {
//                 annotations: {
//                     lowRange: {
//                         drawTime: 'beforeDatasetsDraw',
//                         type: 'box',
//                         yMin: 0,
//                         yMax: ranges.low,
//                         backgroundColor: rangeColors.low,
//                         borderWidth: 0,
//                     },
//                     mediumRange: {
//                         drawTime: 'beforeDatasetsDraw',
//                         type: 'box',
//                         yMin: ranges.low,
//                         yMax: ranges.medium,
//                         backgroundColor: rangeColors.medium,
//                         borderWidth: 0,
//                     },
//                     highRange: {
//                         drawTime: 'beforeDatasetsDraw',
//                         type: 'box',
//                         yMin: ranges.medium,
//                         yMax: 'max', // Use 'max' to extend the band to the top of the chart
//                         backgroundColor: rangeColors.high,
//                         borderWidth: 0,
//                     },
//                 },
//             },
//             tooltip: {
//                 backgroundColor: theme.palette.primary.main,
//                 titleColor: theme.palette.primary.contrastText,
//                 bodyColor: theme.palette.primary.contrastText,
//                 padding: 10,
//                 callbacks: {
//                     label: function (context: any) {
//                         const nullValues = Object.keys(graphData)
//                             .sort()
//                             .reduce((acc, key, index) => {
//                                 if (graphData === null) {
//                                     acc.push(index);
//                                 }
//                                 return acc;
//                             }, [] as number[]);
//                         if (nullValues.includes(context.dataIndex)) {
//                             return "No Data";
//                         }
//                         return `${context.formattedValue} µg/m\u207B\u00B3`;
//                     },
//                 },
//             },
//         },
//         scales: {
//             x: {
//                 type: 'time',
//                 time: {
//                     unit: unit,
//                     tooltipFormat: 'MMM d, h:mm a', // Full date and time for tooltip
//                     displayFormats: {
//                         hour: displayFormat, // Format based on the range
//                         day: displayFormat,
//                         week: displayFormat,
//                         month: 'MMM yyyy', // Monthly format
//                     },
//                 },
//                 grid: {
//                     display: false,
//                 },
//                 title: {
//                     text: 'Time',
//                     display: true,
//                 },
//             },
//             y: {
//                 beginAtZero: true, // Start the y-axis at 0
//                 max: ymax, // Dynamic y-axis maximum
//                 grid: {
//                     display: false,
//                 },
//                 title: {
//                     text: "µg/m\u207B\u00B3",
//                     display: true,
//                 },
//                 ticks: {
//                     color: "black",
//                 },
//             },
//         },
//         elements: {
//             bar: {
//                 borderWidth: Object.keys(graphData)
//                     .sort()
//                     .map((key, index) => {
//                         return Object.keys(graphData)?.length - 1 === index ? 3 : 1;
//                     }),
//             },
//         },
//         maintainAspectRatio: false,
//         responsive: true,
//     };
// };
//
export const getColour = (value: number | null, chosenPollutant: PollutantType, isColorBlindMode: boolean, theme: Theme, chosenGuideline: ChosenGuideline) => {
    if (value === null) return "#808080";
    const ranges = chosenGuideline === 'WHO' ? whoPollutantRanges : defraPollutantRanges;
    const currentRange = ranges[chosenPollutant];
    if (value <= currentRange.low) {
        return isColorBlindMode ? theme.palette.secondary.light : GREEN;
    } else if (value > currentRange.low && value <= currentRange.medium) {
        return ORANGE;
    } else if (value > currentRange.medium) {
        return PURPLE;
    }
};

// export const createChartData = (pollutantData: PollutantDataEntry[], pollutant: PollutantType, isColorBlindMode: boolean, theme: Theme, chosenGuideline: ChosenGuideline) => {
//     const labels = pollutantData.map((entry) => Object.keys(entry)[0]);
//     const dataPoints = pollutantData.map((entry) => entry[Object.keys(entry)[0]]);
//     const backgroundColors = dataPoints.map((value) => getColour(value, pollutant, isColorBlindMode, theme, chosenGuideline));
//     return {
//         labels,
//         datasets: [
//             {
//                 label: pollutant,
//                 data: dataPoints,
//                 backgroundColor: backgroundColors,
//                 borderColor: 'black',
//                 borderWidth: 2,
//                 pointRadius: 3,
//                 fill: false,
//             },
//         ],
//     };
// };

// In utils/chartUtils.ts, update the createChartData function:


export const createChartData = (pollutantData: PollutantDataEntry[], pollutant: PollutantType, isColorBlindMode: boolean, theme: Theme, chosenGuideline: ChosenGuideline) => {
    const labels = pollutantData.map((entry) => Object.keys(entry)[0]);
    const dataPoints = pollutantData.map((entry) => entry[Object.keys(entry)[0]]);
    const backgroundColor = dataPoints.map((value) => getColour(value, pollutant, isColorBlindMode, theme, chosenGuideline));

    return {
        labels,
        datasets: [
            // First dataset for the smooth line
            {
                label: pollutant,
                data: dataPoints,
                borderColor: 'rgba(0, 0, 0, 0.3)', // Semi-transparent black for the line
                borderWidth: 1.5,
                tension: 0.4, // Smooth curve
                fill: false,
                pointRadius: 0, // No points for this dataset
                order: 1 // Draw on top
            },
            // Second dataset for the colored points
            {
                label: pollutant,
                data: dataPoints,
                backgroundColor: backgroundColor,
                borderColor: 'black',
                borderWidth: 1,
                tension: 0.4,
                fill: false,
                pointRadius: 4,
                pointHoverRadius: 6,
                order: 2 // Draw underneath
            }
        ],
    };
};

const getTimeFormats = (unit: TimeUnit) => {
    switch (unit) {
        case 'hour':
            return {
                tooltipFormat: 'MMM d, h:mm a',
                displayFormat: 'h:mm a'
            };
        case 'day':
            return {
                tooltipFormat: 'MMM d, yyyy',
                displayFormat: 'MMM d'
            };
        case 'week':
            return {
                tooltipFormat: 'MMM d, yyyy',
                displayFormat: 'MMM d'
            };
        case 'month':
            return {
                tooltipFormat: 'MMM yyyy',
                displayFormat: 'MMM yyyy'
            };
        default:
            return {
                tooltipFormat: 'MMM d, yyyy',
                displayFormat: 'MMM d'
            };
    }
};


// Update createChartOptions to optimize for line display:
export const createChartOptions = (
    chosenPollutant: PollutantType,
    graphData: PollutantDataEntry[],
    chosenInterval: Interval,
    startDate: Dayjs,
    endDate: Dayjs,
    chosenGuideline: ChosenGuideline,
    title: string,
    theme: Theme
): ChartOptions => {
    const ranges = chosenGuideline === 'WHO' ? whoPollutantRanges[chosenPollutant] : defraPollutantRanges[chosenPollutant];

    // Calculate dynamic y-axis maximum
    const allPollutantValues = graphData.map(entry => entry[Object.keys(entry)[0]]).filter(value => value !== null) as number[];
    const maxPollutantValue = Math.max(...allPollutantValues);
    const ymax = Math.max(100, Math.ceil((maxPollutantValue + 10) / 10) * 10);

    // const {unit, displayFormat} = getTimeScaleUnit(startDate, endDate);
    const unit = chosenInterval;
    const timeFormats = getTimeFormats(unit);

    // Calculate dynamic y-axis maximum

    return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: title,
                font: {
                    size: 18,
                }
            },
            annotation: {
                annotations: {
                    lowRange: {
                        drawTime: 'beforeDatasetsDraw',
                        type: 'box',
                        yMin: 0,
                        yMax: ranges.low,
                        backgroundColor: rangeColors.low,
                        borderWidth: 0,
                    },
                    mediumRange: {
                        drawTime: 'beforeDatasetsDraw',
                        type: 'box',
                        yMin: ranges.low,
                        yMax: ranges.medium,
                        backgroundColor: rangeColors.medium,
                        borderWidth: 0,
                    },
                    highRange: {
                        drawTime: 'beforeDatasetsDraw',
                        type: 'box',
                        yMin: ranges.medium,
                        yMax: 'max',
                        backgroundColor: rangeColors.high,
                        borderWidth: 0,
                    },
                },
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                backgroundColor: theme.palette.primary.main,
                titleColor: theme.palette.primary.contrastText,
                bodyColor: theme.palette.primary.contrastText,
                padding: 10,
                callbacks: {
                    label: function(context: any) {
                        if (context.datasetIndex === 1) {
                            if (context.raw === null) {
                                return "No Data";
                            }
                            const value = context.raw;
                            let category = '';
                            if (value <= ranges.low) {
                                category = 'Low';
                            } else if (value <= ranges.medium) {
                                category = 'Medium';
                            } else {
                                category = 'High';
                            }
                            return `${context.formattedValue} µg/m\u207B\u00B3 - ${category}`;
                        }
                        return "";

                    },
                },
            },
            legend: {
                display: false
            }
        },
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: unit,
                    tooltipFormat: timeFormats.tooltipFormat,
                    displayFormats: {
                        hour: timeFormats.displayFormat,
                        day: timeFormats.displayFormat,
                        week: timeFormats.displayFormat,
                        month: timeFormats.displayFormat,
                    },
                },
                grid: {
                    display: false,
                },
                title: {
                    text: 'Time',
                    display: true,
                },
                ticks: {
                    maxRotation: 0,
                    autoSkip: true,
                    maxTicksLimit: 8, // Limit number of ticks to prevent overcrowding
                }
            },
            y: {
                beginAtZero: true,
                max: ymax,
                grid: {
                    display: false,
                },
                title: {
                    text: "µg/m\u207B\u00B3",
                    display: true,
                },
                ticks: {
                    color: "black",
                },
            },
        },
    };
};

export const getTimeScaleUnit = (startDate: Dayjs, endDate: Dayjs): { unit: TimeUnit, displayFormat: string } => {
    const rangeInHours = endDate.diff(startDate, 'hour');
    let unit: TimeUnit;
    let displayFormat: string;
    if (rangeInHours <= 24) {
        unit = 'hour';
        displayFormat = 'h:mm a'; // Show only time for the same day
    } else if (rangeInHours <= 3 * 24) {
        unit = 'hour';
        displayFormat = 'h:mm a'; // Hourly up to 3 days
    } else if (rangeInHours <= 7 * 24) {
        unit = 'day';
        displayFormat = 'h:mm a'; // Every 3 hours for up to 7 days
    } else if (rangeInHours <= 14 * 24) {
        unit = 'day';
        displayFormat = 'h:mm a'; // Every 12 hours for up to 14 days
    } else if (rangeInHours <= 30 * 24) {
        unit = 'day';
        displayFormat = 'h:mm a'; // Daily for up to 30 days
    } else {
        unit = 'week';
        displayFormat = 'h:mm a'; // Weekly for more than 30 days
    }
    return {unit, displayFormat};
};
