import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BottomNavigation, BottomNavigationAction, Paper, Box } from '@mui/material';
import { navigate } from "vike/client/router";
import MapIcon from '@mui/icons-material/Map';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleOutline from "@mui/icons-material/PeopleOutline";
import ChatIcon from "@mui/icons-material/Chat";
import {AppDispatch} from "/@/store";
import {useDispatch} from "react-redux";
import {resetChat} from "/@/store/slices/airAwareAssistantSlice";


interface AppBottomNavigationProps {
    setValue: (index: number) => void;
    value: number;
}

const appRoutes = [
    { path: '/', displayText: 'map', icon: <MapIcon />, ariaLabel: 'map' },
    { path: '/assistant', displayText: 'assistant_button', icon: <ChatIcon />, ariaLabel: 'assistant' },
    { path: '/infohub', displayText: 'infohub', icon: <InfoIcon />, ariaLabel: 'infohub' },
    { path: '/community', displayText: 'community', icon: <PeopleOutline />, ariaLabel: 'community' },
    { path: '/settings', displayText: 'settings', icon: <SettingsIcon />, ariaLabel: 'settings' },
];

const AppBottomNavigation: React.FC<AppBottomNavigationProps> = ({setValue, value}) => {
    const { t } = useTranslation(); // your translation hook
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();


    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Paper
            sx={{
                zIndex: 2,
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                display: { xs: "block", md: isMobile ? "block" : "none" },
                height: "90px",
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
            }}
            elevation={3}
        >
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                showLabels
                sx={{
                    height: '100%',
                    '.MuiBottomNavigationAction-root': {
                        paddingTop: '6px', // pushes the icon and label up a bit
                        height: '100%', // maintains full height
                        '& .MuiBottomNavigationAction-icon': {
                            height: 'auto', // adjusts icon height to fill the space
                            '& > *': { // targets SVG icons specifically
                                fontSize: '1.5rem', // increase icon size
                            }
                        },
                        '& .MuiBottomNavigationAction-label': {
                            fontSize: '0.75rem', // adjust label font size if needed
                        },
                        '&.Mui-selected': {
                            backgroundColor: theme.palette.secondary.main,
                            color: theme.palette.common.white,
                        },
                    },
                }}
            >
                {appRoutes.map((route, index) => (
                    <BottomNavigationAction
                        key={index}
                        label={
                            <Box sx={{
                                maxWidth: "70px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "center",
                            }}>
                                {t(route.displayText)}
                            </Box>
                        }
                        icon={route.icon}
                        value={index}
                        aria-label={t(route.ariaLabel)}
                        onClick={() => {
                            if (route.path) {
                                if (route.path === "/assistant") {
                                    dispatch(resetChat())
                                }
                                navigate(route.path);
                            }
                        }}
                    />
                ))}
            </BottomNavigation>
        </Paper>
    );
};

export default AppBottomNavigation;
