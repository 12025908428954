import {Box, Button, Link, Skeleton, Typography, useTheme,} from "@mui/material";
import {GoogleMap, InfoWindowF, MarkerClustererF, MarkerF, PolygonF,} from "@react-google-maps/api";
import { differenceInWeeks } from 'date-fns'; // Make sure to import this

import React, {useEffect, useState} from "react";
import {useMapContext} from "/@/context/mapContext";
import MapKey from "./MapKey";
import LiveDataToggle from "./LiveDataToggle";
import {t} from "i18next";
import {CLUSTER_IMAGES, customCalculator, getMarker} from "/@/utils/mapUtils";
import MapLocationSettings from "./MapLocationSettings";
import {ChosenGraphSensor, MapCenterCoords, SensorData} from "/@/types/mapTypes";
import {useMapData} from "/@/context/useMapData";
import boroughBoundaries from '/@/components/map/boroughBoundaries.json'
import {RestartAltRounded} from "@mui/icons-material";
import {Trans} from "react-i18next";
import {styled} from "@mui/material/styles";
import {useThemeContext} from "/@/context/themeContext";
import {keyframes} from "@emotion/react";
// import MapDataViewDropdown from "/@/components/map/MapDataViewDropdown";

const fadeInAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const AnimatedMapContainer = styled(Box)(({theme}) => ({
    animation: `${fadeInAnimation} 1s ease-out`,
    height: "100%", // Ensure the container fills its parent's height
    width: "100%", // Ensure the container fills its parent's width
    position: 'relative'
}));

interface Props {
    toggleDrawer: (boolean: boolean) => void;
    sensorData: SensorData[];
    isMapPage: boolean;
    setSelectedSensor : (sensorId: string) => void;
    selectedSensorId : string;
    centerCoords?: MapCenterCoords;
}

const polygonColourOptions = ["#006699", "#339999", "#e6a9fc", "#edd83d"];

const styles = {
    mapContainer: {
        height: "100%",
        width: "100%",
        borderRadius: "5px 5px 5px 30px",

    },
    mapContainerMobile: {
        height: "100%",
        width: "100%",
        borderRadius: "5px 5px 5px 5px",

    },
};

const MapLocationSettingsBox = styled(Box)(({theme}) => ({
    position: "absolute",
    top: theme.spacing(0.625),
    left: theme.spacing(0.625),
}));

const ResetViewButtonBox = styled(Box)(({theme}) => ({
    position: "absolute",
    bottom: theme.spacing(3.125),
    right: theme.spacing(8.125),
    border: "1px solid black",
    borderRadius: '5px',
}));

const MapPinRangeToggleBox = styled(Box)(({theme}) => ({
    position: "absolute",
    top: theme.spacing(0.625),
    right: theme.spacing(0.625),
}))

const StyledSkeleton = styled(Skeleton)(({theme}) => ({
    width: '100%',
    height: '100%',
}));

const StyledTypography = styled(Typography)(({theme}) => ({
    paddingTop: theme.spacing(0.5),
    textAlign: 'right',
    width: '100%',
}));

const highlightMarkerIcon = (iconUrl: any) : string => {
    const iconSize = {
        width: 40,
        height: 40,
    };
    fetch(iconUrl)
        .then((response) => {
            if (!response.ok) throw new Error('Network response was not ok');
            return response.text();
        }).then((svgText) => {
        const highlightedSvg = `
        <svg xmlns="http://www.w3.org/2000/svg" width="${iconSize.width}px" height="${iconSize.height}px" viewBox="0 0 ${40} ${40}">
          <circle cx="${iconSize.width / 2}" cy="${iconSize.height / 2}" r="${iconSize.width / 2}" fill="yellow" />
          ${svgText}
        </svg>`;
        return `data:image/svg+xml;charset=UTF-8;base64,${btoa(highlightedSvg)}`;
    })
        .catch((error) => {
            console.error('There has been a problem with your fetch operation:', error);
            return iconUrl;
        });
    return iconUrl;
};

// const MapPinRangeToggleMemo = React.memo(MapDataViewDropdown);
const MapKeyMemo = React.memo(MapKey);


const Map: React.FC<Props> = ({
                                  toggleDrawer,
                                  sensorData,
                                  isMapPage,
                                  setSelectedSensor,
                                  selectedSensorId,
                                  centerCoords
                              }) => {
    const theme = useTheme();
    const {isMobile} = useThemeContext();
    const {
        getMapCenter,
        setChosenGraphSensor,
        setMapRef,
        mapRef,
        selectedIFrameMap,
        chosenGuideline,
        locationCoords,
    } = useMapContext();

    const {
        isMapLoaded,
        fetchAirData,
        getSensorReadingsByDateRange
    } = useMapData();

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                await fetchAirData(false); // Load initial data
            } catch (error) {
                console.error('Error loading initial map data:', error);
            }
        };
        loadInitialData();
    }, []); // Empty dependency array for initial load only


    const [center, setCenter] = useState<MapCenterCoords | undefined>(centerCoords ?? undefined);
    const [isInfoWindowOpen, setIsInfoWindowOpen] = useState<boolean>(false);
    const [infoWindowData, setInfoWindowData] = useState<SensorData & { id: number; displayTime: string }>();
    const [isOnline, setIsOnline] = useState(true);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const updateOnlineStatus = () => setIsOnline(navigator.onLine);

            window.addEventListener('online', updateOnlineStatus);
            window.addEventListener('offline', updateOnlineStatus);

            // Initial check
            updateOnlineStatus();

            // Cleanup
            return () => {
                window.removeEventListener('online', updateOnlineStatus);
                window.removeEventListener('offline', updateOnlineStatus);
            };
        }
    }, []);

    const resetToCenter = () => {
        if (centerCoords) console.log("Using center coordinates")
        const center = centerCoords ?? getMapCenter();
        setCenter(center);
        if (center && center.lat && center.lng) mapRef?.panTo({lat: center.lat, lng: center.lng})
        mapRef?.setZoom(12);
    }

    useEffect(() => {
        resetToCenter()
    }, [ centerCoords]);


    const onLoad = (map: google.maps.Map) => {
        setMapRef(map);
    };

    useEffect(() => {
        if (locationCoords?.lat && locationCoords?.lng)
            mapRef?.panTo({lat: locationCoords?.lat, lng: locationCoords?.lng});
        mapRef?.setZoom(16);
    }, [locationCoords]);

    const handleMarkerClick = (id: number, site: SensorData) => {
        const date = new Date(site.created_at as string);
        const displayTime = `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")} ${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getFullYear()}`;

        setInfoWindowData({ ...site, displayTime });
        setIsInfoWindowOpen(true);
    };

    if (!isOnline) return (
        <Typography textAlign={"center"} color={theme.palette.primary.main}>
            Please go online to use the map
        </Typography>
    )

    if (selectedIFrameMap !== null) return (
        <Box sx={{width: '100%', height: '100%', borderRadius: '10px'}}>
            <iframe
                src={selectedIFrameMap}
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    borderRadius: "10px",
                }}
                title="Iframe Title"
                allowFullScreen
            />
        </Box>
    )

    const handleViewBreakdown = (sensorData: ChosenGraphSensor) => {
        setChosenGraphSensor(sensorData);
        if (isMobile) {
            toggleDrawer(true);
        }
    };



    return isMapLoaded ? (
        <>
            <AnimatedMapContainer id="tour-map">
                <GoogleMap
                    key={'gmap'}
                    id="tour-map"
                    mapContainerStyle={(isMobile || !isMapPage) ? styles.mapContainerMobile : styles.mapContainer}
                    center={center}
                    zoom={localStorage.getItem("userMetadata") ? 14 : 12}
                    onLoad={onLoad}
                    options={{
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        minZoom: 1,
                        gestureHandling: isMobile ? 'greedy' : 'auto',
                    }}
                >
                    {mapRef && sensorData && (
                        <MarkerClustererF
                            options={{
                                maxZoom: 10,
                                calculator: customCalculator,
                                title: "Air Aware Cluster",
                                styles: [
                                    {
                                        url: CLUSTER_IMAGES.low,
                                        height: 75,
                                        width: 75,
                                        textColor: "#fff",
                                    },
                                    {
                                        url: CLUSTER_IMAGES.medium,
                                        height: 75,
                                        width: 75,
                                        textColor: "#fff",
                                    },
                                    {
                                        url: CLUSTER_IMAGES.high,
                                        height: 75,
                                        width: 75,
                                        textColor: "#fff",
                                    },
                                    {
                                        url: CLUSTER_IMAGES.noData,
                                        height: 75,
                                        width: 75,
                                        textColor: "#fff",
                                    },
                                ],
                            }}
                        >
                            {(clusterer) => (
                                <div>
                                    {sensorData.map((siteSensorData: SensorData, index) => {
                                        if (siteSensorData === null) return;
                                        return (
                                            <MarkerF
                                                aria-label={'site ' + siteSensorData.name}
                                                key={siteSensorData.id || (siteSensorData?.name ?? "")}
                                                position={{
                                                    lat: parseFloat(siteSensorData.lat),
                                                    lng: parseFloat(siteSensorData.lng),
                                                }}
                                                clusterer={clusterer}
                                                options={{
                                                    icon: isMapPage ? getMarker(siteSensorData, chosenGuideline).icon : selectedSensorId === siteSensorData.id.toString() ? highlightMarkerIcon(getMarker(siteSensorData, chosenGuideline).icon) : getMarker(siteSensorData, chosenGuideline).icon,
                                                    label: {
                                                        text: siteSensorData?.name ?? "",
                                                        className: "visually-hidden"
                                                    },
                                                }}
                                                onClick={() => isMapPage ? handleMarkerClick(index, siteSensorData): setSelectedSensor(siteSensorData.id.toString())}
                                                onLoad={(marker) => {
                                                    marker.setValues({
                                                        pm10: siteSensorData.avg_pm10,
                                                        pm25: siteSensorData.avg_pm25,
                                                        no2: siteSensorData.avg_no2,
                                                        level: getMarker(siteSensorData, chosenGuideline).level,
                                                    });
                                                }}
                                            >
                                                {isMapPage && isInfoWindowOpen && infoWindowData?.id === siteSensorData.id && (
                                                    <InfoWindowF
                                                        options={{maxWidth: 300, minWidth: 250}}
                                                        onCloseClick={() => {
                                                            setIsInfoWindowOpen(false);
                                                        }}
                                                    >
                                                        <Box>
                                                            <Typography variant="h6">
                                                                {infoWindowData.name}
                                                            </Typography>
                                                            <Typography variant="caption">
                                                                {t("data_source", {
                                                                    name: infoWindowData?.provider
                                                                        ? infoWindowData?.provider?.charAt(0).toUpperCase() +
                                                                        infoWindowData?.provider?.slice(1)
                                                                        : "",
                                                                })}
                                                            </Typography>
                                                            <Typography variant="body1" fontSize={"0.8rem"}>
                                                                <i>
                                                                    {t("last_updated", {
                                                                        time: infoWindowData.displayTime,
                                                                    })}
                                                                </i>
                                                            </Typography>
                                                            {infoWindowData.pm10 && (
                                                                <Typography variant="body1">
                                                                    <strong>PM10:</strong>{" "}
                                                                    {`${Number(infoWindowData.pm10).toFixed(2)} µg/m`}
                                                                    <sup>3</sup>
                                                                </Typography>
                                                            )}
                                                            {infoWindowData.pm25 && (
                                                                <Typography variant="body1">
                                                                    <strong>PM2.5:</strong>{" "}
                                                                    {`${Number(infoWindowData.pm25).toFixed(2)} µg/m`}
                                                                    <sup>3</sup>
                                                                </Typography>
                                                            )}
                                                            {infoWindowData.no2 && (
                                                                <Typography variant="body1">
                                                                    <strong>NO2:</strong>{" "}
                                                                    {`${Number(infoWindowData.no2).toFixed(2)} µg/m`}
                                                                    <sup>3</sup>
                                                                </Typography>
                                                            )}
                                                            <Button
                                                                variant="contained"
                                                                color={"info"}
                                                                sx={{marginTop: 1}}
                                                                aria-label="View breakdown"
                                                                onClick={() => {
                                                                    const sensorData = {
                                                                        id: infoWindowData.id,
                                                                        name: infoWindowData.name,
                                                                        lat: infoWindowData?.lat ?? "",
                                                                        lng: infoWindowData?.lng ?? "",
                                                                        created_at: infoWindowData?.created_at
                                                                    };
                                                                    setChosenGraphSensor(sensorData as ChosenGraphSensor);
                                                                    if (isMobile) {
                                                                        toggleDrawer(true);
                                                                    }
                                                                    // Note: Removed direct call to getSensorReadingsByDateRange()
                                                                    // It will be triggered by useEffect in GraphSettings
                                                                }}
                                                            >
                                                                {t("view_breakdown")}
                                                            </Button>
                                                        </Box>
                                                    </InfoWindowF>
                                                )}
                                            </MarkerF>
                                        );
                                    })}
                                </div>)}
                        </MarkerClustererF>
                    )}
                    {isMapPage && <MapLocationSettingsBox id={'tour-address-search'}>
                        <MapLocationSettings/>
                    </MapLocationSettingsBox>}

                    <ResetViewButtonBox id={'tour-reset-view'}>
                        <Button
                            aria-label={'Reset View Button'}
                            variant="contained"
                            endIcon={<RestartAltRounded/>}
                            onClick={() => {
                                resetToCenter();
                            }}
                        >
                            {t('reset_view')}
                        </Button>
                    </ResetViewButtonBox>
                    {/*{!isMobile && isMapPage && (*/}
                    {/*    <MapPinRangeToggleBox id='tour-data-toggle'>*/}
                    {/*        <MapPinRangeToggleMemo/>*/}
                    {/*    </MapPinRangeToggleBox>*/}
                    {/*)}*/}
                    <MapKeyMemo/>
                    {boroughBoundaries.map((path: any, i) => {
                        return (
                            <PolygonF
                                key={i}
                                path={path[0]}
                                options={{
                                    strokeWeight: 1.5,
                                    fillOpacity: 0.3,
                                    fillColor: polygonColourOptions[i],
                                    strokeColor: polygonColourOptions[i],
                                }}
                            />
                        );
                    })}
                </GoogleMap>

                {isMapPage && <StyledTypography variant="caption" color="primary">
                    <Trans i18nKey={"source_declaration"}>
                        {t("source_declaration")}{" "}
                        <Link target="_blank" href="https://www2.purpleair.com/">
                            PurpleAir
                        </Link>
                        ,{" "}
                        <Link
                            target="_blank"
                            href="https://www.londonair.org.uk/LondonAir/Default.aspx"
                        >
                            London Air
                        </Link>
                        ,{" "}
                        <Link
                            target="_blank"
                            href="https://www.airqualityengland.co.uk/"
                        >
                            AirQualityEngland
                        </Link>{" "}
                        &{" "}
                        <Link target="_blank" href="https://www.breathelondon.org/">
                            Powered by Breathe London
                        </Link>
                    </Trans>
                </StyledTypography>}
            </AnimatedMapContainer>
        </>

    ) : (
        <StyledSkeleton variant="rectangular"/>
    );
};

export default Map;
