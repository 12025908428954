import dayjs from "dayjs";
import {ChosenPeriod, MapCenterCoords, TimePeriod} from "/@/types/mapTypes";
import {PollutantType} from "/@/constants/chartContants";

export const POLLUTANT_OPTIONS = ["pm10", "pm25", "no2"] as const;

export const METRIC_OPTIONS = ["avg", "max", "min"] as const;

export const METRIC_OPTION_TO_LABEL: Record<string, string> = {
    avg: "Average",
    max: "Max",
    min: "Min",
}
export const INTERVAL_OPTIONS = ["hour", "day", "week", "month"] as const;

export const INTERVAL_OPTION_TO_LABEL: Record<string, string> = {
    hour: "Hourly",
    day: "Daily",
    week: "Weekly",
    month: "Monthly",
}
export const MAP_VIEW_OPTIONS = ["last-day", "last-week", "last-month", "last-year", "match-graphs"] as const;
export const POLLUTANT_LIMITS = {
    DEFRA: {
        PM10: { '24h': 50, annual: 40 },
        'PM2.5': { annual: 20 },
        NO2: { hourly: 200, annual: 40 },
    },
    WHO: {
        PM10: { '24h': 45, annual: 15 },
        'PM2.5': { '24h': 15, annual: 5 },
        NO2: { '24h': 25, annual: 10 },
    },
};

export const INITIAL_BOROUGH = "Hackney";

export const DEFAULT_MAP_CENTER: MapCenterCoords = {
    lat: 51.5072,
    lng: -0.1276,
};

export const BOROUGH_LOCATIONS = {
    "Hackney": {lat: 51.5436, lng: -0.0554},
    "Newham": {lat: 51.5255, lng: 0.0352},
    "City of London": {lat: 51.515556, lng: -0.093056},
    "Tower Hamlets": {lat: 51.5203, lng: -0.0293},
};

export const whoPollutantRanges: Record<PollutantType, {
    low: number;
    medium: number;
    high: number
}> = {
    'PM2.5': {low: 15, medium: 22, high: 100}, // WHO 24-hour limit for PM2.5 is 15 µg/m³
    'PM10': {low: 45, medium: 67, high: 100}, // WHO 24-hour limit for PM10 is 45 µg/m³
    'NO2': {low: 25, medium: 37, high: 100}, // WHO 24-hour limit for NO2 is 25 µg/m³
};
export const defraPollutantRanges: Record<PollutantType, {
    low: number;
    medium: number;
    high: number
}> = {
    'PM2.5': {low: 35, medium: 53, high: 100},
    'PM10': {low: 50, medium: 75, high: 100},
    'NO2': {low: 50, medium: 75, high: 100},
};