import React, { useEffect, useRef, useState } from 'react';
import { DotLottiePlayer } from "@dotlottie/react-player";
import { Box } from "@mui/material";
// @ts-ignore
import idleAnimation from "/@/assets/animations/idle.lottie";
// @ts-ignore
import thinkingAnimation from "/@/assets/animations/assistant-response-loading.json";

interface AirAwareMascotProps {
    isLoading: boolean;
    isMobile?: boolean;
    style?: React.CSSProperties;
    animationRef: React.RefObject<any>;
}


export const AirAwareMascot: React.FC<AirAwareMascotProps> = ({ isLoading, isMobile, style, animationRef }) => {
    // const [shouldSwitchToIdle, setShouldSwitchToIdle] = useState(false);
    //
    // useEffect(() => {
    //     if (animationRef.current) {
    //         if (isLoading || !shouldSwitchToIdle) {
    //             if (animationRef.current.src !== thinkingAnimation) {
    //                 animationRef.current.load(thinkingAnimation);
    //             }
    //         } else {
    //             if (animationRef.current.src !== idleAnimation) {
    //                 animationRef.current.load(idleAnimation);
    //             }
    //         }
    //     }
    // }, [isLoading]);
    //
    // const handleAnimationSwitch = () => {
    //     console.log("Checking animation switch")
    //     if (!isLoading) {
    //         setShouldSwitchToIdle(true);
    //     } else {
    //         setShouldSwitchToIdle(false);
    //     }
    // }

    return (
        <Box sx={style ?? {}}>
            <DotLottiePlayer
                // onAnimationEnd={handleAnimationSwitch}
                // onAnimationEndCapture={handleAnimationSwitch}
                // onAnimationIteration={handleAnimationSwitch}
                ref={animationRef}
                style={{ width: '160px', height: '100%', display: 'flex', justifyContent: 'center', transform: 'translate3d(-47px, -7px, 0px)', transition: 'opacity 2s ease-in-out',
                }}
                key={isLoading ? 'thinking' : 'idle'}
                src={isLoading ? thinkingAnimation : idleAnimation}
                autoplay
                loop
            />
        </Box>
    );
};

export default AirAwareMascot;
