import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from 'i18next-resources-to-backend';

i18n.use(initReactI18next) // passes i18n down to react-i18next
        .use(LanguageDetector)
        .use(resourcesToBackend((language: string, ns: string) => import(`../locales/${language}/translation.json`)))
        .init({
            fallbackLng: 'en',
            supportedLngs: ["ar", "bn", "de", "en", "es", "fr", "hi", "it", "ja", "pl", "pt", "tr", "zh", "gu", "pa", "ta", "so", "ur"],
            debug: false,
            interpolation: {
                escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
            }
        })


export default i18n;
