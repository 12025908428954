import React, {useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from "/@/store";
import {Box, Card, Collapse, Grid, useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from "react-i18next";
import {styled} from "@mui/material/styles";
import AirAwareMessage from "/@/components/chat/AirAwareMessage";
import ChatInput from '/@/components/chat/ChatInput';
import InitialSuggestions from '/@/components/chat/InitialSuggestions';
import ChatStickyHeader from '/@/components/chat/ChatStickyHeader';
import PossibleUserMessages from "/@/components/chat/PossibleUserMessages";
import {addNewMessage} from "/@/store/slices/airAwareAssistantSlice";
import {formatMessage, messageAssistant} from "/@/utils/assistantUtils";
import AirAwareMascot from "/@/components/chat/AirAwareMascot";

const Wrapper = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
}));


const ChatContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
}));

interface MainContentProps {
    isMobile: boolean;
    isDialogue: boolean;
}

const MainContent = styled(Card, {
    shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'isDialogue'
})<MainContentProps>(({theme, isMobile, isDialogue}) => ({
    background: 'linear-gradient(to bottom, #d6f5ff, 20%, #cee7ef 100%)',
    borderRadius: '15px',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    paddingLeft: isMobile ? '10px' : '50px',
    paddingRight: isMobile ? '10px' : '50px',
    marginLeft: isDialogue ? 0 : isMobile ? '10px' : '50px',
    marginRight: isDialogue ? 0 : isMobile ? '10px' : '50px',
    marginBottom: isMobile ? '10px' : 0,
    maxHeight: isMobile ? '100vh' : 'calc(100vh - 120px)',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    height: '100%'
}));

type PageProps = {
    isDialogue?: boolean; // `isDialogue` is optional and boolean
};

function Page({isDialogue = false}: PageProps) {
    const animationRef = useRef<any>(null);

    const bottomOfChatRef = useRef<HTMLDivElement>(null);
    const {i18n} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));


    const {
        chatHistory,
        sources,
        chatId,
        status, possible_user_messages, possible_user_messages_status,
    } = useSelector((state: RootState) => state.airAwareAssistant);
    const isLoadingPossibleMessages = possible_user_messages_status === "loading";

    const scrollToEnd = () => {
        if (bottomOfChatRef.current) {
            bottomOfChatRef.current.scrollIntoView({behavior: "smooth", block: 'start'});
        }
    }
    // disabled as doesn't work when streaming response
    useEffect(() => {
        if (!isMobile) scrollToEnd()
    }, [chatHistory]);

    const handleButtonPress = async (newMessage: string) => {
        dispatch(addNewMessage(memoizedFormatUserMessage(newMessage)));
        const currentLanguage = i18n.language;
        await messageAssistant(chatHistory, newMessage, dispatch, chatId, currentLanguage, sources)
        scrollToEnd()
    }

    const memoizedFormatUserMessage = useCallback(
        (userMessage: string) => formatMessage(userMessage),
        [],
    );

    return (
        <Wrapper sx={{height: isDialogue ? '100vh' : isMobile ? 'calc(100vh - 100px)' : '100vh'}}>
            <ChatStickyHeader isDialogue={isDialogue}/>
            <MainContent isMobile={isMobile} isDialogue={isDialogue}>
                <ChatContainer sx={{paddingTop: isMobile ? '50px': chatHistory.length === 0? '10px': '50px'}}>
                    {chatHistory.length === 0 && <InitialSuggestions/>}
                    {chatHistory.map((message, index) => {
                        const isLastMessage = index === chatHistory.length - 1;
                        const isSecondToLastMessage = index === chatHistory.length - 2;
                        const isThirdToLastMessage = index === chatHistory.length - 3;
                        const isUserMessage = message.sender === "user";
                        const isPreviousIntermediateChatMessage = chatHistory[index - 1]?.sender === "user" && !isUserMessage && !isLastMessage
                        const showMascot = !isMobile && (isUserMessage && (isLastMessage || isSecondToLastMessage || isThirdToLastMessage))

                        return (
                            <React.Fragment key={index}>
                                <Box sx={{"position": "relative"}}>
                                    {showMascot &&
                                        <AirAwareMascot isLoading={status === "loading"} animationRef={animationRef}
                                                        style={{zIndex: 999, position: "absolute", top: 100}}/>}
                                </Box>
                                <Box sx={{"position": "relative"}}>
                                    <Collapse in={(!isPreviousIntermediateChatMessage)}>
                                        <AirAwareMessage
                                            ref={index === chatHistory.length - 1 ? bottomOfChatRef : null}
                                            message={message}
                                        />
                                    </Collapse>
                                </Box>
                            </React.Fragment>
                        );
                    })}
                    {status === "loading" &&
                        <AirAwareMessage
                            message={{
                                sender: "assistant",
                                structured_message: {
                                    messageElements: []
                                },
                            }}
                            loading={true}
                        />

                    }
                    <Grid
                        container
                        justifyContent="flex-end"
                        id="possibleQuestions"
                        sx={{width: isMobile ? '100%' : '80%', ml: 'auto'}}
                    >
                        {!(status === "loading") && <PossibleUserMessages
                            possibleUserMessages={possible_user_messages && possible_user_messages.length > 0 ? possible_user_messages : []}
                            handleReply={async (message: string) => {
                                if (!isLoadingPossibleMessages) await handleButtonPress(message);
                            }}
                            loading={isLoadingPossibleMessages}
                        />}
                    </Grid>
                    <Box ref={bottomOfChatRef}/>
                </ChatContainer>
                <ChatInput scrollToEnd={scrollToEnd}/>
            </MainContent>
        </Wrapper>
    );
}

export default Page;
