import {AppBar, Box, IconButton, Toolbar, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";

export const CustomHeader = ({title, onClick}: { title: string,  onClick?: () => void  }) => (
    <AppBar position="static" elevation={0}>
        <Toolbar variant="dense">
            <Typography variant="h6" color="inherit">
                {title}
            </Typography>
            <Box flexGrow={1} />
            <IconButton onClick={onClick} edge="end" color="inherit">
                <CloseIcon />
            </IconButton>
        </Toolbar>
    </AppBar>
);

export const CustomLeftArrowButton = ({onClick}: { onClick: () => void }) => (
    <IconButton onClick={onClick} sx={{ml: 2, zIndex: 9999}}>
        <ArrowBackIosIcon color={'success'} fontSize={'large'}/>
    </IconButton>
);

export const CustomRightArrowButton = ({onClick}: { onClick: () => void }) => (
    <IconButton onClick={onClick}>
        <ArrowForwardIosIcon color={'success'} fontSize={'large'}/>
    </IconButton>
);