import { Libraries } from "@react-google-maps/api";

export const areas = ["hackney", "newham", "cityoflondon", "towerhamlets"];
// "cityOfLondon", "towerHamlets"
export const breatheLondonAreas = [
  "Hackney",
  "Newham",
  "City of London",
  "Tower Hamlets",
];

export const boroughBoundingBox = {
  southEastLat: 51.5003,
  southEastLng: 0.0552,
  northWestLat: 51.5934,
  northWestLng: -0.1122,
};

export const purpleAirFields = "name,latitude,longitude,pm10.0_atm,pm2.5_atm";

export const googleLibraries: Libraries = ["places"];
