import React, {useCallback} from 'react';
import {styled, useTheme} from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import {Grid, Typography, IconButton, TextField, CircularProgress} from '@mui/material';
import {addNewMessage} from "/@/store/slices/airAwareAssistantSlice";
import {messageAssistant, formatMessage} from "/@/utils/assistantUtils";
import {useTranslation} from "react-i18next";
import {AppDispatch, RootState} from "/@/store";
import {useDispatch, useSelector} from "react-redux";

const ChatInputContainer = styled(Grid)(({theme}) => ({
}));

const ChatInput = ({scrollToEnd = () => {}}) => {
    const theme = useTheme();
    const {t, i18n} = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const {chatHistory, sources, chatId, status} = useSelector((state: RootState) => state.airAwareAssistant);
    const [message, setMessage] = React.useState('');


    const handleKeypress = (e: any) => {
        if (e.keyCode == 13 && !e.shiftKey) {
            if (message.length < 1) {
                // setErrorMessage(t("assistant.enter_message_error"));
                e.preventDefault();
                return;
            } else {
                // setErrorMessage("");
                sendMessage(e);
                scrollToEnd();
                e.preventDefault();
            }
        }
    };

    const sendMessage = async (e: any) => {
        e.preventDefault();
        if (message.length < 1 || status === 'loading') {
            return;
        }
        const currentLanguage = i18n.language;
        const newMessage = "" + message;
        setMessage("");
        dispatch(addNewMessage(memoizedFormatUserMessage(message)))
        await messageAssistant(chatHistory, newMessage, dispatch, chatId, currentLanguage, sources)
    }

    const memoizedFormatUserMessage = useCallback(
        (userMessage: string) => formatMessage(userMessage),
        [],
    );

    return (
        <ChatInputContainer container>
            <Grid item xs={10} md={12}>
                <Grid container>
                    <Grid item xs>
                        <TextField
                            key={'chat-input-field'}
                            multiline
                            minRows={1}
                            maxRows={10}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={handleKeypress}
                            placeholder={t("send_message_enter")}
                            variant="outlined"
                            sx={{
                                borderRadius: "15px",
                                borderColor: "rgba(0,0,0,0.1)",
                                backgroundColor: theme.palette.common.white,
                                width: "100%",
                                fontFamily: "Poppins",
                                border: "1.49px solid #479696",
                                resize: "none",
                                outline: 'none',
                                "& fieldset": { border: 'none' },
                            }}
                            aria-label="message"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        {status === 'loading' ? <CircularProgress size={40}
                                                                  style={{
                                                                      marginLeft: theme.spacing(2),
                                                                      marginTop: theme.spacing(1),
                                                                      borderRadius: "50%",
                                                                      color: theme.palette.secondary.light,
                                                                  }}
                            /> :
                            <IconButton
                                aria-label={'Send message'}
                                disabled={message?.length === 0}
                                onClick={sendMessage}
                                style={{
                                    marginLeft: theme.spacing(2),
                                    marginTop: theme.spacing(1),
                                    borderRadius: "50%",
                                    color: theme.palette.common.white,
                                    backgroundColor: (message?.length === 0) ? theme.palette.grey[300] : theme.palette.secondary.light,
                                }}
                            >
                            <SendIcon sx={{fontSize: 30}}/>
                        </IconButton>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent="center" alignItems="center" marginTop={2}>
                <Typography paragraph fontSize={9} color={"black"}>
                    {t("assistant.reminder_text")}
                </Typography>
            </Grid>
        </ChatInputContainer>
    );
}

export default ChatInput;