import React from 'react';
import { Box } from '@mui/material';

interface IFrameProps {
    html: string;
    isMobile: boolean;
}

const HtmlEmbed: React.FC<IFrameProps> = ({ html, isMobile }) => {
    let updatedHtml = html;
    if (isMobile && html.includes("youtube")) {
        updatedHtml = html.replace("style='width: 100%; height: 75vh'", "style='width: 100%; height: 20vh'")
    }
    return (
        <Box>
            <div dangerouslySetInnerHTML={{ __html: updatedHtml }} />
        </Box>
    );
};

export default HtmlEmbed;