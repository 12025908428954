import {Box, Grid, IconButton, useMediaQuery, useTheme} from "@mui/material";
import AirAwareMascot from "/@/components/chat/AirAwareMascot";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import React from "react";
import {styled} from "@mui/material/styles";
import { resetChat} from "/@/store/slices/airAwareAssistantSlice";
import {AppDispatch, RootState} from "/@/store";
import {useDispatch, useSelector} from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {useChatContext} from "/@/context/chatContext";
import AirAwareMascotMobile from "/@/components/chat/AirAwareMascotMobile";

const StickyHeader = styled(Box)(({theme}) => ({
    position: 'sticky',
    top: 0,
    zIndex: 10,
    background: undefined,
    padding: theme.spacing(1),
}));

type StickyHeaderProps = {
    isDialogue?: boolean; // `isDialogue` is optional and boolean
};

const ChatStickyHeader = ({isDialogue = false}: StickyHeaderProps) => {

    const dispatch: AppDispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const {toggleOpen} = useChatContext();

    const {
        status, chatHistory
    } = useSelector((state: RootState) => state.airAwareAssistant);
    const isLoadingResponse = status === "loading";

    const resetAssistantChat = async () => {
        dispatch(resetChat())
    }


    return (
        <StickyHeader>
            {isMobile && <AirAwareMascotMobile isLoading={isLoadingResponse} isMobile={isMobile} style={{position: "absolute",
                marginLeft: "auto",
                marginRight: "auto",
                left: 0,
                right: 0,
                textAlign: "center",
                top: "-22.5px"
            }} />}

            <Grid container>
                <Grid item xs={6} sx={{pl: isMobile ? -1 : 2}}>
                    <Box sx={{width: '100px', height: '40px', display: 'flex', justifyContent: 'center'}}></Box>
                </Grid>
                <Grid item xs={6} textAlign={"right"}>
                    {chatHistory.length !== 0 && <IconButton
                        sx={{
                            mr: isMobile ? '5px' : isDialogue ? '5px': '40px',
                            backgroundColor: theme.palette.primary.light,
                            borderRadius: "8px",
                            border: "1px solid #C91C1C",
                            zIndex: 2
                        }}
                        aria-label={'Reset chat'}
                        onClick={resetAssistantChat}
                    >
                        <RestartAltIcon sx={{color: "#C91C1C"}}/>
                    </IconButton>}
                    {isDialogue && <IconButton
                        sx={{
                            backgroundColor: theme.palette.primary.light,
                            boxShadow: "0px 3px 19.7px 0px rgba(0, 0, 0, 0.25)",
                            borderRadius: "8px",
                        }}
                        onClick={toggleOpen}
                        aria-label={'Close modal'}
                    >
                        <CloseIcon sx={{color: "#C91C1C"}}/>
                    </IconButton>}
                </Grid>
            </Grid>
        </StickyHeader>
    )
}

export default ChatStickyHeader;
