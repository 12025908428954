import React, {useEffect} from "react";
import {ListItemButton, ListItemIcon, Typography, Box, ListItem} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "@mui/material";
import {AppDispatch, RootState} from "/@/store";
import {RouteType} from "/@/routes/config";
import {cloneElement, isValidElement} from "react";
import {useTranslation} from "react-i18next";
import {motion} from "framer-motion";
import {navigate} from "vike/client/router";
import InfoHubArticleCard from "/@/components/infoHub/InfoHubArticleCard";
import {resetChat} from "/@/store/slices/airAwareAssistantSlice";

type Props = {
    index: number,
    item: RouteType;
};


const SidebarItem = React.memo(({index, item}: Props) => {
    const theme = useTheme();
    const {appState} = useSelector((state: RootState) => state.appState);
    const {t, ready} = useTranslation();
    const dispatch: AppDispatch = useDispatch();


    const buttonVariants = {
        initial: {
            x: -20,
            opacity: 0
        },
        animate: {
            x: 0,
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: index * 0.2 // delay each button
            }
        },
        hover: {scale: 1.1},
        tap: {scale: 0.95}
    }


    return (
        item.sidebarProps && item.path ? (
            <ListItem
                aria-label={t(item.sidebarProps.displayText || 'sidebar item')}
                sx={{
                    "&:hover": {
                        backgroundColor: theme.palette.secondary.main,
                    },
                    backgroundColor: appState === item.path ? theme.palette.secondary.main : "unset",
                    color: appState === item.path ? theme.palette.primary.main : "unset",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    borderRadius: "10px 0px 0px 10px",
                    marginBottom: '10px',
                    marginLeft: '5px',
                    textAlign: 'center',
                    width: '100%',
                    paddingLeft: '6px',
                    paddingRight: '0px'
                }}
            >
                <motion.div variants={buttonVariants} initial="initial" animate="animate"
                            aria-label={t(item.sidebarProps.displayText || 'sidebar item')}
                >
                    <Box
                        component={motion.div}
                        whileHover={{
                            scale: 1.1,
                            transition: {duration: 0.3}
                        }}
                        whileTap={{scale: 0.9}}
                    >
                        <ListItemButton
                            aria-label={t(item.sidebarProps.displayText || 'sidebar item')}
                            onClick={() => {
                                if (typeof window !== 'undefined' && item.path !== undefined) {
                                    if (item.path === "/assistant") {
                                        dispatch(resetChat())
                                    }
                                    navigate(item.path)
                                }
                            }}
                            sx={{
                                "&:hover": {
                                    backgroundColor: theme.palette.secondary.main,
                                },
                                backgroundColor: appState === item.path ? theme.palette.secondary.main : "unset",
                                color: appState === item.path ? theme.palette.primary.main : "unset",
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                borderRadius: "10px 0px 0px 10px",
                                textAlign: 'center',
                                width: '100%',
                                paddingLeft: '6px',
                                paddingRight: '0px'
                            }}
                        >

                            <ListItemIcon sx={{
                                color: appState === item.path ? '#fff' : theme.palette.secondary.light,
                                justifyContent: 'center',
                                paddingRight: '17px',
                            }}>
                                {
                                    isValidElement(item.sidebarProps.icon)
                                        // @ts-ignore
                                        ? cloneElement(item.sidebarProps.icon, {style: {fontSize: '30px'}})
                                        : item.sidebarProps.icon
                                }
                            </ListItemIcon>
                            <Typography sx={{paddingRight: '17px',}}
                                        variant="h6" component="p">{t(item.sidebarProps.displayText || '')}</Typography>
                        </ListItemButton>
                    </Box>
                </motion.div>
            </ListItem>
        ) : null
    );
});
SidebarItem.displayName = "SidebarItem";
export default SidebarItem;