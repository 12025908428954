export const followUpQuestionsPrompt = `Based on the current conversation with the user, provide up to 3 nuanced follow up messages that the user could ask to continue the conversation and further learn about air pollution.
The messages should be relevant to the users personal circumstances and the current conversation. Consider questions like research paper or media searches to add more depth to the conversation.
`;

export const followUpSchema = {
    name: "FollowUp",
    description:
        "Generates up to 3 follow up questions based on the users personal circumstances and the current conversation.",
    parameters: {
        type: "object",
        properties: {
            follow_up_messages: {
                type: "array",
                title: "Follow Up Messages",
                description:
                    "A list of follow up messages that the user could ask the Air Aware Assistant",
                items: {
                    title: "Follow Up Message",
                    type: "string",
                },
                max_properties: 3,
                min_properties: 1,
            },
        },
        required: ["follow_up_messages"],
    },
};


export type Link = {
    url: string;
    label: string;
    position: number;
    quoteId: string;
    title: string;
    organisation: string;
    quote?: string;
};


export type ContentType = "header1" | "header2" | "header3" | "header4" | "header5" | "header6" | "unorderedListElement" | "orderedListElement" | "tableHeader" | "tableRow" | "tableSeparator" | "newline" | "text" | "intermediateMessage";

export type MessageElement = {
    index: number;
    id: string;
    content: string;
    isFinalMessage: boolean;
    type: ContentType;
    links?: Link[];
    embed?: string;
    image?: string;
    graphData?: PollutantReadings;
    mapSensorData?: SensorData[];
    graphDataPerSensor?: Record<string, PollutantReadings>
    selectedSensor?: string;
    mapSensorDataCenter? : { lat: number, lng: number };
};

export interface StructuredMessage {
    messageElements: MessageElement[];
}

export interface ChatMessage {
    sender: string;
    structured_message: StructuredMessage;
}

export interface Message {
    role: "system" | "assistant" | "user";
    content: string;
}


export type PollutantReadings = {
    [key in "pm25" | "pm10" | "no2"]: Array<{
        [timestamp: string]: number | null;
    }>;
};

export interface MapSensorData {
    sensor_id: string;
    lat: string;
    lng: string;
    name: string;
    pm10?: number;
    pm25?: number;
    no2?: number;
}

export type SensorData  = {
    id: number;
    name: string;
    lat: string;
    lng: string;
    pm10: string;
    pm25: string;
    no2: string;
    created_at?: string;
    provider?: string;
}

type MapArray = SensorData[];
type MapsArray = MapArray[];


export interface Paragraph {
    content: string;
    list: boolean;
    text: string;
    links: Link[];
    image?: string;
    embed?: string;
    newlineAbove?: boolean;
    newlineBelow?: boolean;
    id: string;
    index: number;
}

interface References {
    title: string;
    sources: Source[];
}

interface Source {
    name: string;
    documents: Document[];
}

interface Document {
    title: string;
    url: string;
    quotes: Quote[];
}

export interface Quote {
    id?: string;
    quoteId: string;
    text: string;
    title: string;
    organisation: string;
}

export interface SourceObject {
    id: string;
    type: string;
    title: string;
    source: string;
    extract: string;
    organisation: string;
    sourceType: string;
    figures?: { imageUrl: string; caption: string }[];
    embeds?: { html: string; caption: string }[];
    date?: string;
    embed?: string;
    image?: string;
    excerpts?: Record<string, string>;
}

export interface SourcesContainer {
    [sourceId: string]: SourceObject & {
        excerptIdToPaperAndContent?: Record<
            string,
            { paper: string; content: string }
        >;
    };
}

export interface StreamedMessage {
    index: number;
    paragraph: Paragraph;
}

export interface ChatState {
    chatHistory: ChatMessage[];
    status: 'idle' | 'initialising' | 'loading' | 'succeeded' | 'failed';
    possible_user_messages_status: 'idle' | 'initialising' | 'loading' | 'succeeded' | 'failed';
    error: null | string;
    possible_user_messages: null | Array<string>
    sources?: SourcesContainer;
    chatId: string;
}

export interface InitialChatResponse {
    response: string;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: null | string;
    sources: [],
    possible_user_messages: [],
}
