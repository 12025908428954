import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Mousewheel, Pagination} from "swiper/modules";
import SuggestionCard from "/@/components/chat/SuggestionCard";
import {useWidth} from "/@/utils/useWidth";
import {ShuffleOutlined} from "@mui/icons-material";
import AirAwareMascot from "/@/components/chat/AirAwareMascot";


interface CardData {
    title: string;
    prompts: string[];
    content?: string;
}


interface DisplayCardData extends CardData {
    displayedPrompts: string[];
}

export default function InitialSuggestions() {
    const animationRef = useRef<any>(null);

    const theme = useTheme();
    const {t, i18n} = useTranslation();
    const width = useWidth();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [numberSlides, setNumberSlides] = useState<number>(1.1);
    const [cardsData, setCardsData] = useState<DisplayCardData[]>([]);

    const shufflePrompts = (prompts: string[]): string[] => {
        const shuffled = [...prompts].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, 3);
    };

    const initialiseCardsWithRandomPrompts = () => {
        const allCards = t('assistant.features.cards', {returnObjects: true}) as CardData[];
        const cardsWithRandomPrompts = allCards.map(card => ({
            ...card,
            displayedPrompts: shufflePrompts(card.prompts),
        }));
        setCardsData(cardsWithRandomPrompts);
    };

    // Refresh handler
    const refreshCards = () => {
        initialiseCardsWithRandomPrompts();
    };

    useEffect(() => {
        initialiseCardsWithRandomPrompts();
    }, [])

    useEffect(() => {
        initialiseCardsWithRandomPrompts();
    }, [i18n.language]);

    useEffect(() => {
        switch (width) {
            case "sm":
                setNumberSlides(1.5);
                break;
            case "md":
                setNumberSlides(2);
                break;
            case "lg":
                setNumberSlides(3);
                break;
            case "xl":
                setNumberSlides(3);
                break;
        }
    }, [width]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            height={'100%'}
            sx={{paddingTop: isMobile? '50px': "0px"}}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" paddingBottom="10px"
                 paddingRight="10px" paddingLeft="10px">
                <Typography sx={{ fontSize: isMobile ? '0.9rem' : '1.25rem' }} variant="h5">
                    {!isMobile && <Box component="span" sx={{ display: 'inline-block', verticalAlign: 'middle', paddingTop: '20px' }}>
                        <AirAwareMascot isLoading={false} animationRef={animationRef} style={{ zIndex: 999 }} />
                    </Box>}
                    <Box sx={{display: 'inline-block', verticalAlign: 'middle', marginLeft: isMobile? "0": "-70px"}}>{t("assistant.features.title")}</Box>
                </Typography>
                <Button variant="contained" color='secondary' onClick={refreshCards} startIcon={<ShuffleOutlined/>}>
                    <Typography>{t('shuffle')}</Typography>
                </Button>
            </Box>
            <Box
                sx={{width: "100%", height: '100%', marginTop: isMobile? 0: '-40px'}}
            >
                <Swiper
                    key={numberSlides}
                    slidesPerView={numberSlides}
                    spaceBetween={10}
                    freeMode={true}
                    pagination={{clickable: true}}
                    modules={[FreeMode, Mousewheel, Pagination]}
                    mousewheel={{forceToAxis: true}}
                    className="mySwiper"
                >
                    {cardsData.map((card, index) => (
                        <SwiperSlide key={index}>
                            <SuggestionCard title={card.title}
                                            suggestions={card.displayedPrompts}
                                            gradientIndex={index}
                            />
                        </SwiperSlide>
                    ))}
                    <Box sx={{pt: isMobile? 5: 0}} className="swiper-custom-pagination"/>
                </Swiper>
            </Box>
        </Box>
    )
}