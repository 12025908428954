import {configureStore} from '@reduxjs/toolkit'
import appStateReducer from './slices/appStateSlice'
import supabaseReducer from './slices/supabaseSlice'
import fontSizeReducer from './slices/fontSizeSlice'
import airAwareAssistantReducer from './slices/airAwareAssistantSlice'
import newsApiReducer from "./slices/newsApiSlice";
import eventReducer from "./slices/eventsSlice";
import infoHubReducer from "./slices/infoHubSlice";
// import {chatStreamMiddleware} from "/@/store/middleware/chatStreamMiddleware";



const store = configureStore({
    reducer: {
        appState: appStateReducer,
        supabase: supabaseReducer,
        fontSize: fontSizeReducer,
        airAwareAssistant: airAwareAssistantReducer,
        newsApiReducer: newsApiReducer,
        infoHubReducer: infoHubReducer,
        eventReducer: eventReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            // .concat(chatStreamMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type AppDispatch = typeof store.dispatch

export type {RootState, AppDispatch}
export {store}