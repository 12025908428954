import React, { createContext, useContext, useEffect, useState } from 'react'
import {hotjar} from "react-hotjar";
import ReactGA from "react-ga4";
import { createClient, SignInWithPasswordCredentials, SignUpWithPasswordCredentials, SupabaseClient } from '@supabase/supabase-js'
import {useDispatch, useSelector} from 'react-redux'
import {setSession} from "/@/store/slices/supabaseSlice";
import {navigate} from "vike/client/router";
import {
  Accessibility,
  Borough,
  Interest,
  Transport,
} from "./onboardingContext";
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import axios from "axios";
import {deleteAccountBlacklist} from "/@/constants/accountList";
import {RootState} from "/@/store";

type User = {
  id: string;
  email: string | undefined;
  name: string | null;
  metadata?: UserMetadata;
};

interface UserMetadata {
  council: Borough | undefined;
  transport: Transport[];
  interest: Interest[];
  accessibility: Accessibility[];
}

type SupabaseContextType = {
  supabase: SupabaseClient | null;
  user: User | null;
  signIn: (credentials: SignInWithPasswordCredentials) => Promise<void>;
  signUp: (
    credentials: SignUpWithPasswordCredentials & {
      name: string;
      borough: Borough | undefined;
      transport: Transport[];
      interest: Interest[];
      accessibility: Accessibility[];
    }
  ) => Promise<void>;
  signOut: () => Promise<void>;
  deleteAccount: () => Promise<void>;
  updateUserData: (updatedData: UserMetadata) => Promise<void>;
  sendResetPasswordEmail: (email: string) => Promise<boolean>;
  updateUserPassword: (email: string) => Promise<boolean>;
};

const SupabaseContext = createContext<SupabaseContextType>({
  supabase: null,
  user: null,
  signIn: async () => {},
  signUp: async () => {},
  signOut: async () => {},
  deleteAccount: async () => {},
  updateUserData: async () => {},
  sendResetPasswordEmail: async (string) => {
    return true;
  },
  updateUserPassword: async (string) => {
    return true;
  },
});

export const useSupabase = () => useContext(SupabaseContext);

type Props = {
  children: React.ReactNode;
};

export const SupabaseProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User | null>(null);

  const dispatch = useDispatch();
  const supabase = useSupabaseClient();

  const getMetadata = (metadata: UserMetadata) => {
    return {
      council: metadata?.council || undefined,
      transport: metadata?.transport || null,
      interest: metadata?.interest || null,
      accessibility: metadata?.accessibility || null,
    };
  };

  useEffect(() => {
    const { data: authSubscription } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        const currentUser = session?.user;
        if (currentUser) {
          const metadata = getMetadata(
            currentUser.user_metadata as UserMetadata
          );
          setUser({
            id: currentUser.id,
            email: currentUser.email,
            name: currentUser.user_metadata?.name || null,
            metadata,
          });
          hotjar.identify(currentUser.id, {email: currentUser.email});
          localStorage.setItem("userMetadata", JSON.stringify(metadata));
          dispatch(setSession(session));
          if (deleteAccountBlacklist.includes(currentUser.id)) {
            // Initialize GA4 tracking with custom dimensions and metrics
            ReactGA.initialize("G-RRMRHTMJFT", {
              gaOptions: {
                user_id: currentUser.id,
                custom_map: {
                  dimension1: 'user_id',
                  dimension2: 'borough',
                  metric1: 'usage_time',
                  metric2: 'page_visits',              },
              },
            });
          }
        } else {
          setUser(null);
          dispatch(setSession(null));
        }
      }
    );

    return () => {
      authSubscription.subscription.unsubscribe();
    };
  }, [dispatch]);

  const signIn = async (credentials: SignInWithPasswordCredentials) => {
    const response = await supabase?.auth.signInWithPassword(credentials);
    if (response?.error) {
      throw response.error;
    }
    if (response?.data?.user) {
      const metadata = getMetadata(
        response.data.user.user_metadata as UserMetadata
      );
      setUser({
        id: response.data.user.id,
        email: response.data.user.email,
        name: response.data.user.user_metadata?.name || null,
        metadata,
      });
      localStorage.setItem("userMetadata", JSON.stringify(metadata));
      dispatch(setSession(response?.data.session));
      navigate("/");
    }
  };

  const updateUserData = async (updatedData: UserMetadata) => {
    const response = await supabase.auth.updateUser({
      data: updatedData,
    });
    if (response.error) {
      throw response.error;
    }

    if (response?.data?.user) {
      const metadata = getMetadata(
        response.data.user.user_metadata as UserMetadata
      );
      setUser({
        id: response.data.user.id,
        email: response.data.user.email,
        name: response.data.user.user_metadata?.name || null,
        metadata,
      });
      navigate("/");
    }
  };

  const signUp = async (
    credentials: SignUpWithPasswordCredentials & {
      name: string;
      borough: Borough | undefined;
      transport: Transport[];
      interest: Interest[];
      accessibility: Accessibility[];
    }
  ) => {
    const { name, borough, transport, interest, accessibility, ...rest } =
      credentials;
    const response = await supabase?.auth.signUp({
      ...rest,
      options: {
        data: { name, council: borough, transport, interest, accessibility },
      },
    });
    if (response?.error) {
      throw response.error;
    }
    if (response?.data?.user) {
      const metadata = getMetadata(
        response.data.user.user_metadata as UserMetadata
      );
      setUser({
        id: response.data.user.id,
        email: response.data.user.email,
        name: response.data.user.user_metadata?.name || null,
        metadata,
      });
      localStorage.setItem("userMetadata", JSON.stringify(metadata));
      dispatch(setSession(response?.data.session));

      navigate("/");
    }
  };

  const signOut = async () => {
    const response = await supabase?.auth.signOut();
    if (response?.error) {
      throw response.error;
    }
    setUser(null);
    localStorage.removeItem("userMetadata");
    dispatch(setSession(null));
    navigate("/");
  };

  const deleteAccount = async () => {
    const { data: userSession, error } = await supabase.auth.getSession();
    if (user && userSession) {
      await axios.post(
        `${import.meta.env.VITE_SUPABASE_URL}/functions/v1/user-delete-account`,
        {
          user_id: user.id,
        },
        {
          headers: {
            Authorization: `Bearer ${userSession.session?.access_token}`,
          },
        }
      );
    }
    await signOut();
    navigate("/");
  };

  const sendResetPasswordEmail = async (email: string) => {
    try {
      const response = await supabase?.auth.resetPasswordForEmail(email, {
        redirectTo: "https://air-aware.co.uk/update-password",
      });
      if (response.error) {
        console.error(response.error);
        return false;
      }
      return !!response.data; // Depends on the response structure
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const updateUserPassword = async (password: string) => {
    try {
      const response = await supabase?.auth.updateUser({ password: password });
      if (response.error) {
        console.error(response.error);
        return false;
      }
      return !!response.data; // Depends on the response structure
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  return (
    <SupabaseContext.Provider
      value={{
        supabase,
        user,
        signIn,
        signUp,
        signOut,
        deleteAccount,
        updateUserData,
        sendResetPasswordEmail,
        updateUserPassword,
      }}
    >
      {children}
    </SupabaseContext.Provider>
  );
};