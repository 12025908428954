import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ChatMessage, ChatState, MessageElement, SourcesContainer} from "/@/types/assistantTypes";

const generateRandomString = () => {
    const timestamp = new Date().getTime();
    const randomNum = Math.random().toString(36).substring(2);
    return `T${timestamp}"R"${randomNum}`;
}


const initialState: ChatState = {
    chatHistory: [],
    status: 'idle',
    possible_user_messages_status: 'idle',
    error: null,
    possible_user_messages: [],
    sources: {},
    chatId: generateRandomString(),
};


export const airAwareAssistantSlice = createSlice({
    name: 'airAwareAssistantSlice',
    initialState,
    reducers: {
        addNewMessage: (state, action: PayloadAction<ChatMessage>) => {
            if (action.payload.sender === 'user') {
                state.status = 'loading';
                state.chatHistory.push(action.payload);
            } else if (action.payload.sender === 'ai') {
                const response = {
                    sender: action.payload.sender,
                    structured_message: action.payload.structured_message
                } as ChatMessage;
                state.chatHistory.push(response);
            }
        },
        addStreamedParagraph: (state, action: PayloadAction<{ message: MessageElement }>) => {
            // if index is 0 then create a new chatMessage and append it to the chatHistory
            if (action.payload.message.index === 0) {
                const newMessage: ChatMessage = {
                    sender: 'ai',
                    structured_message: {
                        messageElements: [action.payload.message],
                    }
                }
                state.chatHistory.push(newMessage);
            } else {
                state.chatHistory[state.chatHistory.length - 1].structured_message.messageElements.push(action.payload.message);
            }
        },
        finaliseChat: (state) => {
            state.status = 'idle';
        },
        updateSources: (state, action: PayloadAction<SourcesContainer>) => {
            state.sources = action.payload;
        },
        stopLoadingBubble: (state) => {
            state.status = 'idle'
        },
        resetChat: (state) => {
            state.chatHistory = [];
            state.possible_user_messages = [];
            state.sources = {};
            state.status = 'idle';
            state.chatId = generateRandomString();
        },
        loadingPossibleUserMessages: (state) => {
            state.possible_user_messages_status = 'loading';
        },
        updatePossibleUserMessages: (state, action: PayloadAction<Array<string>>) => {
            state.possible_user_messages = action.payload;
            state.possible_user_messages_status = 'idle';
        },
    },
    extraReducers: (builder) => {

    },
});


export const {
    addNewMessage,
    updateSources,
    updatePossibleUserMessages,
    loadingPossibleUserMessages,
    resetChat,
    stopLoadingBubble,
    finaliseChat,
    addStreamedParagraph
} = airAwareAssistantSlice.actions;

export default airAwareAssistantSlice.reducer