import { createSlice } from "@reduxjs/toolkit";

const fontSizeSlice = createSlice({
  name: "font",
  initialState: {
    size: 14
  },
  reducers: {
    incrementSize: (state) => {
      state.size += 1;
    },
    decrementSize: (state) => {
      state.size -= 1;
    },
  },
});

export const { incrementSize, decrementSize } = fontSizeSlice.actions;
export default fontSizeSlice.reducer;