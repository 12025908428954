import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, Dialog, Fab, Icon, Slide, SvgIcon, useMediaQuery, useTheme,} from '@mui/material';
import {TransitionProps} from "@mui/material/transitions";
import {useChatContext} from '/@/context/chatContext';
import {usePageContext} from "/@/renderer/usePageContext";
import Page from "/@/pages/assistant/+Page";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const whiteListPathnames = ["/infohub", "/community",];

const CloudIcon = (props: any) => (
        <SvgIcon viewBox="216.373 120.892 76 76" {...props}>
            <circle cx="254.373" cy="158.892" r="38" fill="#D1C1F5"/>
            <path
                d="M 276.879 149.246 C 275.469 148.578 273.969 148.258 272.456 148.251 C 271.271 144.93 268.526 142.218 264.842 141.212 C 261.433 140.279 257.958 141.009 255.286 142.902 C 252.412 140.397 248.326 139.403 244.419 140.637 C 241.038 141.704 238.525 144.207 237.34 147.251 C 231.878 148.135 227.375 152.923 226.677 159.138 C 225.852 166.489 230.666 173.051 237.429 173.793 C 239.695 174.042 241.891 173.606 243.844 172.64 C 245.297 173.872 247.008 174.888 248.931 175.601 C 254.175 177.545 259.719 176.768 263.745 173.995 C 264.043 174.175 264.35 174.344 264.669 174.495 C 270.624 177.314 278.184 173.947 281.556 166.975 C 284.928 160.003 282.833 152.065 276.879 149.246 Z"
                fill="white"/>
            <path
                d="M 254.899 176.892 C 252.885 176.892 250.836 176.53 248.856 175.796 C 246.998 175.107 245.302 174.13 243.813 172.889 C 241.8 173.858 239.589 174.242 237.406 174.002 C 230.536 173.247 225.63 166.568 226.466 159.114 C 227.156 152.971 231.555 148.034 237.186 147.064 C 238.461 143.886 241.068 141.474 244.354 140.437 C 248.174 139.232 252.252 140.054 255.299 142.636 C 258.112 140.701 261.6 140.109 264.897 141.009 C 268.457 141.982 271.327 144.605 272.604 148.043 C 274.138 148.07 275.606 148.412 276.969 149.057 C 283.02 151.921 285.163 160 281.747 167.066 C 280.094 170.482 277.403 173.157 274.168 174.598 C 270.92 176.045 267.514 176.076 264.578 174.685 C 264.309 174.558 264.038 174.413 263.753 174.245 C 261.159 175.996 258.072 176.894 254.899 176.894 L 254.899 176.892 Z M 243.875 172.39 L 243.982 172.481 C 245.46 173.733 247.149 174.716 249.006 175.405 C 254.022 177.264 259.486 176.673 263.625 173.823 L 263.738 173.744 L 263.857 173.816 C 264.173 174.007 264.469 174.168 264.761 174.306 C 267.584 175.643 270.862 175.61 273.994 174.215 C 277.138 172.815 279.755 170.212 281.364 166.884 C 284.68 160.027 282.627 152.199 276.787 149.435 C 275.438 148.796 273.98 148.467 272.455 148.459 L 272.305 148.459 L 272.255 148.319 C 271.05 144.944 268.258 142.361 264.784 141.412 C 261.556 140.53 258.14 141.133 255.409 143.071 L 255.272 143.167 L 255.145 143.057 C 252.198 140.49 248.213 139.66 244.484 140.836 C 241.283 141.846 238.751 144.211 237.538 147.325 L 237.494 147.437 L 237.374 147.457 C 231.873 148.347 227.561 153.16 226.887 159.16 C 226.077 166.385 230.816 172.855 237.453 173.585 C 239.599 173.821 241.776 173.43 243.749 172.452 L 243.875 172.389 L 243.875 172.39 Z"
                fill="#999999"/>
            <path
                d="M 248.579 155.56 C 247.43 155.512 246.416 156.37 246.009 157.606 L 247.796 158.724 L 245.889 159.543 C 246.152 160.882 247.118 161.893 248.312 161.943 C 249.761 162.003 250.996 160.624 251.07 158.861 C 251.143 157.098 250.028 155.621 248.579 155.561 L 248.579 155.56 Z"
                fill="black"/>
            <path
                d="M 261.177 155.56 C 260.017 155.512 258.995 156.37 258.584 157.606 L 260.388 158.724 L 258.463 159.543 C 258.729 160.882 259.704 161.893 260.909 161.943 C 262.372 162.003 263.618 160.624 263.692 158.861 C 263.767 157.098 262.641 155.621 261.177 155.561 L 261.177 155.56 Z"
                fill="black"/>
        </SvgIcon>
    )
;

const AirAwareChatModal = () => {
    const [isWhiteListed, setIsWhiteListed] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const {open, message, toggleOpen} = useChatContext();
    const pageContext = usePageContext();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const whiteListed = whiteListPathnames.includes(pageContext.urlPathname);
            setIsWhiteListed(whiteListed);
        }
    }, [pageContext.urlPathname]);

    const handleModal = () => {
        toggleOpen();
    };

    return (
        <div>
            {
                <Box sx={{display: isWhiteListed ? undefined : "none"}}>
                    <Fab
                        color="primary"
                        id="airAwareModal"
                        sx={{
                            position: "fixed",
                            bottom: isMobile ? "100px" : "20px",
                            right: isMobile ? "10px" : "20px",
                            fontSize: 50,
                        }}
                        onClick={handleModal}
                        aria-label={'Air Aware Chat Button'}
                    >
                        <CloudIcon style={{fontSize: 50}}/>
                    </Fab>
                </Box>
            }
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleModal}
                aria-describedby="alert-dialog-slide-description"
                maxWidth={false}
                fullScreen={isMobile}
                PaperProps={{
                    style: {
                        marginLeft: isMobile ? "0" : "160px",
                        width: isMobile ? undefined : `calc(100% - 160px)`,
                        borderRadius: isMobile ? 0 : "10px",
                        backgroundColor: theme.palette.secondary.main,
                    },
                }}
            >
                <Page isDialogue={true}/>
            </Dialog>
        </div>
    );
};

export default React.memo(AirAwareChatModal);