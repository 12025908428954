// import React, { useEffect, useState, useRef } from 'react';
// import { Typography } from "@mui/material";
//
// export type ContentPiece = string | JSX.Element;
//
// interface TypewriterProps {
//     content: ContentPiece[];
//     typingSpeed?: number;
//     onTypingComplete: () => void;
//     component?: React.ElementType;
//     componentProps?: Record<string, any>;
//     style?: React.CSSProperties;
// }
//
// const Typewriter: React.FC<TypewriterProps> = ({
//                                                    content,
//                                                    typingSpeed = 3,
//                                                    onTypingComplete,
//                                                    component: Component = Typography,
//                                                    componentProps = {},
//                                                    style = {}
//                                                }) => {
//     const [displayedContent, setDisplayedContent] = useState<ContentPiece[]>([]);
//     const [currentContentIndex, setCurrentContentIndex] = useState(0);
//     const [charIndex, setCharIndex] = useState(0);
//     const [isFinished, setIsFinished] = useState(false);
//
//     const currentContentIndexRef = useRef(currentContentIndex);
//     const charIndexRef = useRef(charIndex);
//
//     useEffect(() => {
//         currentContentIndexRef.current = currentContentIndex;
//         charIndexRef.current = charIndex;
//     }, [currentContentIndex, charIndex]);
//
//     useEffect(() => {
//         if (isFinished) return;
//
//         const currentContent = content[currentContentIndex];
//
//         if (typeof currentContent === 'string') {
//             if (charIndex < currentContent.length) {
//                 const timeoutId = setTimeout(() => {
//                     setDisplayedContent((prev) => [...prev, currentContent[charIndexRef.current]]);
//                     setCharIndex(charIndexRef.current + 1);
//                 }, typingSpeed);
//                 return () => clearTimeout(timeoutId);
//             } else {
//                 setTimeout(() => {
//                     setCurrentContentIndex(currentContentIndex + 1);
//                     setCharIndex(0);
//                     if (currentContentIndex + 1 >= content.length) {
//                         onTypingComplete();
//                         setIsFinished(true);
//                     }
//                 }, 0);
//             }
//         } else {
//             setDisplayedContent((prev) => [...prev, currentContent]);
//             setTimeout(() => {
//                 setCurrentContentIndex(currentContentIndex + 1);
//                 if (currentContentIndex + 1 >= content.length) {
//                     onTypingComplete();
//                     setIsFinished(true);
//                 }
//             }, 0);
//         }
//     }, [content, currentContentIndex, charIndex, typingSpeed, onTypingComplete, isFinished]);
//
//     return (
//         <Component {...componentProps} style={style}>
//             {displayedContent.map((item, index) => (
//                 <React.Fragment key={index}>{item}</React.Fragment>
//             ))}
//         </Component>
//     );
// };
//
// export default Typewriter;

import React, { useEffect, useState, useRef } from 'react';
import { Typography } from "@mui/material";

export type ContentPiece = string | JSX.Element;

interface TypewriterProps {
    content: ContentPiece[];
    typingSpeed?: number;
    onTypingComplete: () => void;
    component?: React.ElementType;
    componentProps?: Record<string, any>;
    style?: React.CSSProperties;
}

const Typewriter: React.FC<TypewriterProps> = ({
                                                   content,
                                                   typingSpeed = 3,
                                                   onTypingComplete,
                                                   component: Component = Typography,
                                                   componentProps = {},
                                                   style = {}
                                               }) => {
    const [displayedContent, setDisplayedContent] = useState<ContentPiece[]>([]);
    const [currentContentIndex, setCurrentContentIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);
    const [isFinished, setIsFinished] = useState(false);
    const currentContentIndexRef = useRef(currentContentIndex);
    const charIndexRef = useRef(charIndex);

    useEffect(() => {
        currentContentIndexRef.current = currentContentIndex;
        charIndexRef.current = charIndex;
    }, [currentContentIndex, charIndex]);

    useEffect(() => {
        if (isFinished) return;

        const currentContent = content[currentContentIndex];
        if (typeof currentContent === 'string') {
            if (charIndex < currentContent.length) {
                const timeoutId = setTimeout(() => {
                    setDisplayedContent((prev) => {
                        const lastItem = prev[prev.length - 1];
                        if (typeof lastItem === 'string') {
                            return [...prev.slice(0, -1), lastItem + currentContent[charIndexRef.current]];
                        } else {
                            return [...prev, currentContent[charIndexRef.current]];
                        }
                    });
                    setCharIndex(charIndexRef.current + 1);
                }, typingSpeed);
                return () => clearTimeout(timeoutId);
            } else {
                setTimeout(() => {
                    setCurrentContentIndex(currentContentIndex + 1);
                    setCharIndex(0);
                    if (currentContentIndex + 1 >= content.length) {
                        onTypingComplete();
                        setIsFinished(true);
                    }
                }, 0);
            }
        } else {
            setDisplayedContent((prev) => [...prev, currentContent]);
            setTimeout(() => {
                setCurrentContentIndex(currentContentIndex + 1);
                if (currentContentIndex + 1 >= content.length) {
                    onTypingComplete();
                    setIsFinished(true);
                }
            }, 0);
        }
    }, [content, currentContentIndex, charIndex, typingSpeed, onTypingComplete, isFinished]);

    return (
        <Component {...componentProps} style={style}>
            {displayedContent.map((item, index) => (
                <React.Fragment key={index}>{item}</React.Fragment>
            ))}
        </Component>
    );
};

export default Typewriter;