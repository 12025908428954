import {
    Box,
    Link as MuiLink,
    TableRow,
    Table, TableBody, TableCell, TableContainer, TableHead,
    ListItem,
    ListItemIcon,
    ListItemText,
    Skeleton,
    Typography,
    useMediaQuery,
    useTheme,
    Chip, Grid, List
} from "@mui/material";
import {styled} from "@mui/material/styles";
import ChatHoverCard, {QuoteLink} from "/@/components/chat/ChatHoverCard";
import HtmlEmbed from "/@/components/HtmlEmbed";
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useThemeContext} from "/@/context/themeContext";
import {ChatMessage, MessageElement, Paragraph, Link, PollutantReadings, MapSensorData} from "/@/types/assistantTypes";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AirQualityChart from "/@/components/graphs/AirQualityChart";
import {PollutantType} from "/@/constants/chartContants";
import Map from "/@/components/map/Map";
import {MapProvider} from "/@/context/mapContext";
import Typewriter, {ContentPiece} from "/@/components/chat/Typewriter";
import {CustomHeader, CustomLeftArrowButton, CustomRightArrowButton} from "/@/components/infoHub/LightBox";
import Lightbox from "react-spring-lightbox";
import {MapCenterCoords, PollutantDataEntry, SensorData} from "/@/types/mapTypes";


const StyledSpan = styled("span")(({ theme }) => ({
    margin: "0 0.15em",
    font: "inherit",
    display: "inline-block",
    padding: "0 0.1em",
    borderRadius: "5px", // Adjusted border radius
    backgroundColor: "#dde1ff", // Background color
    color: "#333", // Text color
    textDecoration: "none", // Remove underline
    fontWeight: 500, // Adjusted font weight
    fontSize: "0.9em", // Adjusted font size
    textAlign: "center",
    minWidth: "1.6em", // Adjusted minimum width for square appearance
}));

const BubbleBox = styled("div")(({theme}) => ({
    position: "relative",
    padding: theme.spacing(2),
    maxWidth: "100%",
    wordWrap: "break-word",
}));

const UserBubble = styled(BubbleBox)(({theme}) => ({
    marginLeft: "auto",
    background: "linear-gradient(135deg, #8241B8,#6C33A3 )",
    color: "white",
    borderRadius: "26.74px 26.74px 0px 26.74px",
    maxWidth: useMediaQuery(theme.breakpoints.down("md")) ? "80vw" : "65vw",
    marginBottom: useMediaQuery(theme.breakpoints.down("md")) ? "20px" : "50px",
    marginTop: useMediaQuery(theme.breakpoints.down("md")) ? "20px" : "50px",
}));

const AIBubble = styled(BubbleBox)(({theme}) => ({
    marginRight: "auto",
    backgroundColor: "#fff",
    borderRadius: "26.74px 26.74px 26.74px 0px",
    minWidth: useMediaQuery(theme.breakpoints.down("md")) ? "60vw" : "40vw",
    maxWidth: useMediaQuery(theme.breakpoints.down("md")) ? "80vw" : "65vw"
}));

const IntermediateMessageAIBubble = styled(BubbleBox)(({theme}) => ({
    marginRight: "auto",
    backgroundColor: "#fff",
    borderRadius: "26.74px 26.74px 26.74px 0px",
    maxWidth: useMediaQuery(theme.breakpoints.down("md")) ? "80vw" : "65vw"
}));

const SpeechBubbleSkeleton = styled(Skeleton)(({theme}) => ({
    borderRadius: "26.74px 26.74px 26.74px 0px",
    position: "relative",
    maxWidth: useMediaQuery(theme.breakpoints.down("sm")) ? "80%" : "50%",
    marginRight: "auto",
}));

interface AirAwareMessageProps {
    loading?: boolean;
    message: ChatMessage
}

const uniqueByTitle = (quoteLinks: QuoteLink[]): QuoteLink[] => {
    const combined: QuoteLink | {} = {};

    quoteLinks.forEach(quoteLink => {
        // @ts-ignore
        if (combined[quoteLink.url]) {
            // @ts-ignore
            const quoteIds = combined[quoteLink.url].quoteId.split(' ');
            if (!quoteIds.includes(quoteLink.quoteId)) {
                // @ts-ignore
                combined[quoteLink.url].quoteId += ` ${quoteLink.quoteId}`;
            }
        } else {
            // @ts-ignore
            combined[quoteLink.url] = {...quoteLink};
        }
    });

    return Object.values(combined);
};


const AirAwareMessage = React.forwardRef<HTMLDivElement, AirAwareMessageProps>((props, ref) => {
    const {message, loading = false} = props;
    const {structured_message, sender} = message;
    const {messageElements} = structured_message
    const isUser = sender === "user";
    const isIntermediateMessage = !isUser && messageElements.length > 0 && messageElements[0].type === "intermediateMessage"
    const BubbleType = isUser ? UserBubble : (isIntermediateMessage ? IntermediateMessageAIBubble : AIBubble);
    const theme = useTheme();
    const allLinks = messageElements.flatMap((messageElement: MessageElement) => messageElement.links || []);
    const uniqueLinks = uniqueByTitle(allLinks);
    const {isMobile} = useThemeContext();
    const [selectedPollutant, setSelectedPollutant] = useState<PollutantType>('PM2.5');
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const [displayedParagraphs, setDisplayedParagraphs] = useState<React.ReactNode[]>([]);
    const [isImageDialogOpen, setImageDialogOpen] = useState(false);
    const [isTyping, setIsTyping] = useState(false);

    const {t} = useTranslation();

    const [selectedSensorId, setSelectedSensorId] = useState<string | undefined>(undefined);
    const [selectedSensorName, setSelectedSensorName] = useState<string | undefined>(undefined);


    const handleImageClick = (url: string): void => {
        const index = images.findIndex((image) => image.src === url);
        setCurrentImageIndex(index);
        setImageDialogOpen(true);

    };

    const gotoPrevious = () =>
        currentImageIndex > 0 && setCurrentImageIndex(currentImageIndex - 1);

    const gotoNext = () =>
        currentImageIndex + 1 < images.length &&
        setCurrentImageIndex(currentImageIndex + 1);

    const replaceLinks = (text: string, links: Link[]) => {
        const parts = text.split(/(\[[^\]]+\])/);
        return parts.map((part, index) => {
            const link = links.find((l) => l.label === part);

            return link ? (
                <ChatHoverCard
                    key={index}
                    link={link}
                />
            ) : (
                part
            );
        });
    };

    const images = messageElements.filter((messageElement) => messageElement.image).map((paragraph) => ({
        src: paragraph.image!,
        alt: "",
    }));


    const [graph, setGraph] = useState<PollutantReadings | undefined>(undefined)
    const [graphsPerSensor, setGraphsPerSensor] = useState<Record<string, PollutantReadings> | undefined>(undefined)
    const [map, setMap] = useState<SensorData[] | undefined>(undefined)
    const [mapCenter, setMapCenter] = useState<MapCenterCoords | undefined>(undefined)

    useEffect(() => {
        if (graphsPerSensor && map) {
            const firstSensorId = Object.keys(graphsPerSensor)[0]
            const selectedSensor = map.find((sensor) => sensor.id === parseInt(firstSensorId))
            setSelectedSensorId(firstSensorId)
            if (selectedSensor) {
                setSelectedSensorName(selectedSensor.name)
            }
        }
    }, [graphsPerSensor]);

    useEffect(() => {
        if (graphsPerSensor && map) {
            if (!selectedSensorId) return;
            const selectedSensor = map.find((sensor) => sensor.id === parseInt(selectedSensorId))
            if (selectedSensor) {
                setSelectedSensorName(selectedSensor.name)
            }
        }
    }, [selectedSensorId]);


    // state to manage what content has already been prepared, it is a set of strings
    const preparedContentIdsRef = useRef<string[]>([]);
    const preparedContentIndicesRef = useRef<number[]>([]);


    useEffect(() => {

        if (currentSectionIndex < messageElements.length && !isTyping) {
            const nextParagraph = messageElements[currentSectionIndex];
            const paragraphContent: React.ReactNode[] = prepareContent(nextParagraph);

            if (paragraphContent.length > 0) {
                setDisplayedParagraphs((prevDisplayed) => [...prevDisplayed, ...paragraphContent]);
                setIsTyping(true);
            } else {
                setCurrentSectionIndex((prevIndex) => prevIndex + 1);
            }
        }
    }, [currentSectionIndex, messageElements, isTyping]);

    function convertToListItems(messageElementContent: string, index: number, messageElementId: string, links: Link[] | undefined): React.ReactNode[] {

        const cellContent = messageElementContent.split("|").filter((cell) => cell.trim())[index]
        const lines = cellContent.split(/<br>|-(?=\s+)/);
        const listItems: React.ReactNode[] = [];

        lines.forEach((line, index) => {
            if (line.trim() !== '') {
                const element : ContentPiece[] = replaceLinks(
                    line,
                    links ?? []
                );
                listItems.push(
                    <ListItem key={"unorderedListElement-" + messageElementId + String(index)}>
                        <ListItemIcon sx={{ minWidth: "30px" }}>
                            <FiberManualRecordIcon sx={{ fontSize: "0.4rem" }} />
                        </ListItemIcon>
                        <ListItemText>
                            <Typewriter
                                content={element}
                                onTypingComplete={handleTypingComplete}
                            />
                        </ListItemText>
                    </ListItem>
                );
            }
        });

        return listItems;
    }


    const prepareContent = (messageElement: MessageElement): React.ReactNode[] => {
        let content: React.ReactNode[] = [];

        if (preparedContentIndicesRef.current.includes(messageElement.index)) {
            return content;
        } else {
            preparedContentIndicesRef.current = [...preparedContentIndicesRef.current, messageElement.index];
        }

        if (preparedContentIdsRef.current.includes(messageElement.id)) {
            return content;
        } else {
            preparedContentIdsRef.current = [...preparedContentIdsRef.current, messageElement.id];
        }


        if (messageElement.type === "newline") {
            content.push(
                <Typewriter
                    content={[<br key={"newline-" + messageElement.id} />]}
                    onTypingComplete={handleTypingComplete}
                />
            );
            return content;
        }

        if (messageElement.content) {
            const messageElementContent: ContentPiece[] = replaceLinks(
                messageElement.content,
                messageElement.links ?? []
            );

            switch (messageElement.type) {
                case "header1":
                    content.push(
                        <Typewriter
                            key={"header1-" + messageElement.id}
                            content={messageElementContent}
                            onTypingComplete={handleTypingComplete}
                            component={Typography}
                            componentProps={{ variant: "h5", gutterBottom: true }}
                        />
                    );
                    break;
                case "header2":
                    content.push(
                        <Typewriter
                            key={"header2-" + messageElement.id}
                            content={messageElementContent}
                            onTypingComplete={handleTypingComplete}
                            component={Typography}
                            componentProps={{ variant: "h6", gutterBottom: true }}
                        />
                    );
                    break;
                case "header3":
                    content.push(
                        <Typewriter
                            key={"header3-" + messageElement.id}
                            content={messageElementContent}
                            onTypingComplete={handleTypingComplete}
                            component={Typography}
                            style={{fontWeight: 'bold'}}
                            componentProps={{ gutterBottom: true }}
                        />
                    );
                    break;
                case "header4":
                    content.push(
                        <Typewriter
                            key={"header4-" + messageElement.id}
                            content={messageElementContent}
                            onTypingComplete={handleTypingComplete}
                            component={Typography}
                            style={{fontWeight: 'bold'}}
                            componentProps={{ gutterBottom: true }}
                        />
                    );
                    break;
                case "header5":
                    content.push(
                        <Typewriter
                            key={"header5-" + messageElement.id}
                            content={messageElementContent}
                            onTypingComplete={handleTypingComplete}
                            component={Typography}
                            componentProps={{ variant: "h8", gutterBottom: true }}
                        />
                    );
                    break;
                case "header6":
                    content.push(
                        <Typewriter
                            key={"header6-" + messageElement.id}
                            content={messageElementContent}
                            onTypingComplete={handleTypingComplete}
                            component={Typography}
                            componentProps={{ variant: "h9", gutterBottom: true }}
                        />
                    );
                    break;
                case "unorderedListElement":
                    content.push(
                        <ListItem sx={{pt: 0, pb: 0}} key={"unorderedListElement-" + messageElement.id}>
                            <ListItemIcon sx={{ minWidth: "30px" }}>
                                <FiberManualRecordIcon sx={{ fontSize: "0.4rem" }} />
                            </ListItemIcon>
                            <ListItemText sx={{mt: 0, mb: 0}}>
                                <Typewriter
                                    content={messageElementContent}
                                    onTypingComplete={handleTypingComplete}
                                />
                            </ListItemText>
                        </ListItem>
                    );
                    break;
                case "orderedListElement":
                    content.push(
                        <ListItem sx={{pt: 0, pb: 0}} key={"orderedListElement-" + messageElement.id}>
                            <ListItemText sx={{mt: 0, mb: 0}}>
                                <Typewriter
                                    content={messageElementContent}
                                    onTypingComplete={handleTypingComplete}
                                />
                            </ListItemText>
                        </ListItem>
                    );
                    break;
                case "tableHeader":
                    const headerCells = messageElement.content.split("|").filter((cell) => cell.trim()).map((cell) => replaceLinks(cell.trim(), messageElement.links || []));
                    content.push(
                        <TableContainer key={`tableHeader-${messageElement.id}`}>
                            <Table>
                                <TableHead sx={{ backgroundColor: 'primary.main' }}>
                                    <TableRow>
                                        {headerCells.map((cell, index) => {
                                            const totalColumns = headerCells.length;
                                            const firstColumnWidth = 100 / (2 * totalColumns - 1);
                                            const remainingColumnsWidth = 2 * firstColumnWidth;

                                            // let cellWidth = `${100 / headerCells.length}%`;
                                            let cellWidth = totalColumns < 4 ? (index === 0 ? `${firstColumnWidth}%` : `${remainingColumnsWidth}%`) : `${100 / headerCells.length}%`

                                            return <TableCell
                                                key={index}
                                                sx={{
                                                    padding: '4px',
                                                    marginBottom: '12px',
                                                    width: cellWidth,
                                                    backgroundColor: "#eeeeee",
                                                }}
                                            >
                                                <Typewriter content={cell} onTypingComplete={handleTypingComplete} style={{'fontWeight': 'bold'}}/>
                                            </TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                    );
                    break;
                case "tableRow":
                    const rowCells = messageElement.content.split("|").filter((cell) => cell.trim()).map((cell) => replaceLinks(cell.trim(), messageElement.links || []));
                    content.push(
                        <TableContainer key={`tableRow-${messageElement.id}`}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        {rowCells.map((cell, index) => {
                                            const isListItem = /^-\s/.test(cell.toString()) || /<br>\s*-\s/.test(cell.toString());
                                            const totalColumns = rowCells.length;
                                            const firstColumnWidth = 100 / (2 * totalColumns - 1);
                                            const remainingColumnsWidth = 2 * firstColumnWidth;

                                            // let cellWidth = `${100 / rowCells.length}%`;
                                            let cellWidth = totalColumns < 4 ? (index === 0 ? `${firstColumnWidth}%` : `${remainingColumnsWidth}%`) : `${100 / rowCells.length}%`

                                            // given cell is (string | JSX.Element)[] replace any '<br> with a newline element
                                            if (cell.toString().includes("<br>")) {
                                                cell = cell.toString().split("<br>").map((cellPart, index) => {
                                                    if (index === 0) {
                                                        return cellPart;
                                                    } else {
                                                        return <br key={index} />;
                                                    }
                                                });
                                            }


                                            return (
                                                <TableCell key={index} sx={{ padding: '4px 4px', width: cellWidth }}>
                                                    {isListItem ? (
                                                        <List>
                                                            {convertToListItems(messageElement.content, index, messageElement.id, messageElement.links)}
                                                        </List>
                                                    ) : (
                                                        <Typewriter content={cell} onTypingComplete={handleTypingComplete} />
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    );
                    break;
                default:
                    content.push(
                        <Typewriter
                            key={`text-${messageElement.id}`}
                            content={messageElementContent}
                            onTypingComplete={handleTypingComplete}
                            componentProps={{"display": "inline"}}
                        />
                    );
            }
        }

        if (messageElement.image) {
            content.push(
                // <Collapse in={true}>

                <Box key={"image-" + messageElement.id}>
                        <button
                            aria-label={"response image"}
                            onClick={() => handleImageClick(messageElement.image!)}
                            style={{
                                border: "none",
                                padding: 0,
                                background: "none",
                                cursor: "pointer",
                            }}
                        >
                            <img
                                src={messageElement.image}
                                alt={messageElement.content}
                                style={{ width: "auto", maxHeight: "70vh", maxWidth: "100%" }}
                            />
                        </button>
                    {/*<Typography variant="caption">{messageElement.content}</Typography>*/}
                </Box>
                // </Collapse>

            );
        }

        if (messageElement.graphData && !graph) {
            setGraph(messageElement.graphData)
        }

        if (messageElement.graphDataPerSensor && !graphsPerSensor) {
            setGraphsPerSensor(messageElement.graphDataPerSensor)
        }

        if (messageElement.mapSensorData && !map) {
            setMap(messageElement.mapSensorData)
        }

        if (messageElement.mapSensorDataCenter && !mapCenter) {
            setMapCenter(messageElement.mapSensorDataCenter)
        }

        // Handle embeds directly, no typewriting needed.
        if (messageElement.embed) {
            content.push(
                // <Collapse >
                    <Box key={"embed-" + messageElement.id}>
                        <HtmlEmbed html={messageElement.embed} isMobile={isMobile} />
                    </Box>
            // </Collapse>
            );
        }

        return content;
    };

    const handleTypingComplete = () => {
        const nextIndex = currentSectionIndex + 1;
        setCurrentSectionIndex(nextIndex);
        setIsTyping(false);
    };


    return (
        <div
            ref={ref}
            style={{
                display: "flex",
                justifyContent: isUser ? "flex-end" : "flex-start",
                width: "100%",
                paddingBottom: "10px",
                alignItems: "center",
            }}
        >
            <Box display="flex" flexDirection="column">
                {loading ? (
                    <SpeechBubbleSkeleton variant="rectangular" height={50} width={200}/>
                ) : (
                    <BubbleType>
                        {sender === "user" ? <>
                            <Typography variant="body1" sx={{pb: 0}}>
                                {replaceLinks(messageElements[0].content, [])}
                            </Typography>
                        </> : displayedParagraphs}


                        {sender !== "user" && (map || (graphsPerSensor && selectedSensorId) || graph) && <Grid container sx={{mt: 2}}>
                            {map && <Grid item xs={12} md={6}>
                                <MapProvider>
                                    <Box
                                        sx={{
                                            height: '450px',
                                            width: '100%',
                                            position: 'relative',
                                            marginBottom: '20px',
                                            paddingRight: isMobile ? undefined : '30px',
                                        }}
                                    >
                                        <Map
                                            toggleDrawer={() => {
                                            }}
                                            sensorData={map}
                                            isMapPage={false}
                                            setSelectedSensor={setSelectedSensorId}
                                            selectedSensorId={selectedSensorId ?? ""}
                                            centerCoords={mapCenter}
                                        />
                                    </Box>
                                </MapProvider>
                            </Grid>
                            }
                            {graphsPerSensor && selectedSensorId &&
                                <Grid item xs={12} md={map ? 6 : 9}>
                                    {selectedSensorName && <Typography gutterBottom>{t('showing_data_for', {chosenSensorName: selectedSensorName})}</Typography>}
                                    <Box display="flex" justifyContent="start" flexWrap="wrap" gap={1}>
                                        {(['PM2.5', 'PM10', 'NO2'] as PollutantType[]).map((pollutant) => (
                                            <Chip
                                                key={pollutant}
                                                label={pollutant}
                                                onClick={() => setSelectedPollutant(pollutant)}
                                                color={selectedPollutant === pollutant ? 'success' : 'default'}
                                            />
                                        ))}
                                    </Box>
                                    <AirQualityChart pollutant={selectedPollutant}
                                                     airQualityData={{
                                                         pm10: graphsPerSensor[selectedSensorId]["pm10"] as unknown as PollutantDataEntry[],
                                                         pm25:  graphsPerSensor[selectedSensorId]["pm25"] as unknown as PollutantDataEntry[],
                                                         no2:  graphsPerSensor[selectedSensorId]["no2"] as unknown as PollutantDataEntry[],
                                                     }}
                                                     isMapPage={false}/>
                                </Grid>
                            }
                            {graph &&
                                <Grid item xs={12} md={map ? 6 : 9}>
                                    {/*<Typography gutterBottom>{t('showing_data_for', {chosenSensorName: ''})}</Typography>*/}
                                    <Box display="flex" justifyContent="start" flexWrap="wrap" gap={1}>
                                        {(['PM2.5', 'PM10', 'NO2'] as PollutantType[]).map((pollutant) => (
                                            <Chip
                                                key={pollutant}
                                                label={pollutant}
                                                onClick={() => setSelectedPollutant(pollutant)}
                                                color={selectedPollutant === pollutant ? 'success' : 'default'}
                                            />
                                        ))}
                                    </Box>
                                    <AirQualityChart pollutant={selectedPollutant}
                                                     airQualityData={{
                                                         pm10: graph["pm10"] as unknown as PollutantDataEntry[],
                                                         pm25:  graph!["pm25"] as unknown as PollutantDataEntry[],
                                                         no2:  graph!["no2"] as unknown as PollutantDataEntry[],
                                                     }}
                                    isMapPage={false}/>
                                </Grid>
                            }
                        </Grid>}


                        {sender !== "user" && allLinks.length > 0 && (
                            <Box sx={{mt: 1}}>
                                <Typography fontSize={"1em"} fontWeight={"bold"} mt={1} mb={0.3}>
                                    {t("assistant.sources_title")}
                                </Typography>
                                {uniqueLinks.map((link, idx) => {
                                    const quoteIds = link.quoteId.split(' ');
                                    return (
                                        <Typography fontSize={"0.8em"} key={idx}>
                                            {quoteIds.map((quoteId, idx) => (

                                                <StyledSpan id={quoteId} key={idx}
                                        >
                                            {quoteId.replace(/[\[\]]/g, '')}
                                        </StyledSpan>
                                            ))}
                                            {": "}
                                            <MuiLink
                                                href={link.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{color: theme.palette.secondary.main}}
                                            >
                                                {link.title}
                                            </MuiLink>
                                        </Typography>
                                    );
                                })}
                            </Box>
                        )}
                    </BubbleType>
                )}
            </Box>
            <Lightbox
                currentIndex={currentImageIndex}
                onPrev={() => setCurrentImageIndex((currentImageIndex + images.length - 1) % images.length)}
                onNext={() => setCurrentImageIndex((currentImageIndex + 1) % images.length)}
                onClose={() => setImageDialogOpen(false)}
                images={images}
                isOpen={isImageDialogOpen}
                style={{backgroundColor: 'rgba(0, 0, 0, 0.8)', zIndex: 9999}}
                renderHeader={() => <CustomHeader title={""} onClick={() => setImageDialogOpen(false)}/>}
                renderPrevButton={() => <CustomLeftArrowButton onClick={gotoPrevious}/>}
                renderNextButton={() => <CustomRightArrowButton onClick={gotoNext}/>}
            />
        </div>
    );
});
AirAwareMessage.displayName = 'AirAwareMessage';

export default AirAwareMessage;