import {
    AllAveragedAirQualityData,
    ChosenGuideline,
    ChosenPeriod,
    Interval,
    MapDateRange,
    SensorData
} from '/@/types/mapTypes'
import markerNoData from "/@/assets/markers/grey-sensor.svg";
import markerGreen from "/@/assets/markers/green-sensor.svg";
import markerMedium from "/@/assets/markers/orange-sensor.svg";
import markerHigh from "/@/assets/markers/purple-sensor.svg";
import clusterGreen from "/@/assets/clusters/green-cluster.svg";
import clusterMedium from "/@/assets/clusters/orange-cluster.svg";
import clusterHigh from "/@/assets/clusters/purple-cluster.svg";
import clusterNoData from "/@/assets/clusters/grey-cluster.svg";
import {INTERVAL_OPTIONS} from "/@/constants/mapConstants";

const OFFSET = 0.0001;
// First value is low, second value is medium, high is any value above the second value
const DEFRA_PM10_THRESHOLDS = [50, 75];
const DEFRA_PM25_THRESHOLDS = [35, 53];
const DEFRA_NO2_THRESHOLDS = [50, 75];
const PM10_THRESHOLDS = [45, 50]; // WHO 24-hour value for PM10
const PM25_THRESHOLDS = [15, 25]; // WHO 24-hour value for PM2.5
const NO2_THRESHOLDS = [25, 50];
export const MARKER_IMAGES = {
    low: markerGreen,
    medium: markerMedium,
    high: markerHigh,
    noData: markerNoData,
};

export const CLUSTER_IMAGES = {
    low: clusterGreen,
    medium: clusterMedium,
    high: clusterHigh,
    noData: clusterNoData,
};


// Helper function to check if a pollutant has data
export const hasPollutantData = (data: AllAveragedAirQualityData, pollutant: string): boolean => {
    // @ts-ignore
    if (!data || !data[pollutant.toLowerCase()]) return false;
    // @ts-ignore
    const pollutantData = data[pollutant.toLowerCase()];
    return pollutantData.some((entry: any) => {
        const value = Object.values(entry)[0];
        return value !== null && value !== undefined;
    });
};

export const lastDayDateRange = (): string => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are 0-based in JavaScript
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}

export const getCurrentDate = (): string => {
    const date = new Date();
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are 0-based in JavaScript
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}

export const lastWeekDateRange = (): string => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are 0-based in JavaScript
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}

export const lastMonthDateRange = (): string => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are 0-based in JavaScript
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}

export const getDateRange = (range: string): MapDateRange => {
    const currentDate = getCurrentDate();
    switch (range) {
        case "last-day":
            return {start_date: lastDayDateRange(), end_date: currentDate};
        case "last-week":
            return {start_date: lastWeekDateRange(), end_date: currentDate};
        case "last-month":
            return {start_date: lastMonthDateRange(), end_date: currentDate};
        default:
            return {start_date: lastDayDateRange(), end_date: currentDate};
    }
}

export const getDaysBetweenDates = (date1: string, date2: string): number => {
    const dateObj1 = new Date(date1);
    const dateObj2 = new Date(date2);
    const timeDiff = Math.abs(dateObj2.getTime() - dateObj1.getTime());
    return Math.floor(timeDiff / (1000 * 3600 * 24));
};

export const decideInterval = (daysBetween: number): string => {
    if (daysBetween <= 5) {
        return "hour";
    } else if (daysBetween <= 30) {
        return "day";
    } else if (daysBetween <= 90) {
        return "week";
    } else {
        return "month";
    }
}

export const filterIntervalOptions = (chosenPeriod: ChosenPeriod) => {
    const startDate = chosenPeriod!
        [0]!.toISOString();
    const endDate = chosenPeriod![1]!.toISOString();
    const daysInBetween = getDaysBetweenDates(startDate, endDate);
    if (daysInBetween <= 7) {
        return INTERVAL_OPTIONS.filter((option) => option !== "month" && option !== "week");
    }
    if (daysInBetween <= 30) {
        return INTERVAL_OPTIONS.filter((option) => option !== "month");
    }
    if (daysInBetween > 30) {
        return INTERVAL_OPTIONS.filter((option) => option !== "hour");
    }
    else {
        return INTERVAL_OPTIONS;
    }
}

export const applyOffsetToCloseSensors = (sensorsData: SensorData[]) => {
    const offsetSensorsData = [...sensorsData]; // Create a copy to prevent mutating the original array
    let alreadyOffset: { [sensorId: string]: boolean } = {}; // Correctly typed object
    // Loop over all sensors
    for (let i = 0; i < offsetSensorsData.length; i++) {
        let sensor1 = offsetSensorsData[i];
        if (!alreadyOffset[sensor1.id]) { // Skip if this sensor has been offset already
            // Compare with every other sensor, not including itself
            for (let j = i + 1; j < offsetSensorsData.length; j++) {
                let sensor2 = offsetSensorsData[j];

                // Check if the sensors are too close to each other
                if (Math.abs(parseFloat(sensor1.lat) - parseFloat(sensor2.lat)) < OFFSET &&
                    Math.abs(parseFloat(sensor1.lng) - parseFloat(sensor2.lng)) < OFFSET) {
                    // Offset the position of the second sensor
                    offsetSensorsData[j] = {
                        ...sensor2,
                        lat: (parseFloat(sensor2.lat) + OFFSET).toString(),
                        lng: (parseFloat(sensor2.lng) + OFFSET).toString()
                    };
                    alreadyOffset[sensor2.id] = true; // Mark this sensor as already offset
                    // No need to check this sensor against others
                    break;
                }
            }
        }
    }
    return offsetSensorsData;
};

/**
 * This function returns the risk level for a given pollutant value based on specified thresholds.
 *
 * @param pollutantValue The concentration value of the pollutant.
 * @param thresholds An array of threshold values that define the boundaries for risk levels.
 * @returns A string representing the risk level.
 */
export const getRiskLevel = (pollutantValue: number, thresholds: number[]): string => {
    if (pollutantValue <= thresholds[0]) {
        return "low";
    } else if (pollutantValue <= thresholds[1]) {
        return "medium";
    } else {
        return "high";
    }
};

export const getMarker = (siteSensorData: SensorData, chosenGuideline: ChosenGuideline) : {icon: string, level: string} => {
    if (siteSensorData.last_pm10 === null && siteSensorData.last_no2 === null && siteSensorData.last_pm25 === null) {
        return {icon: markerNoData, level: "noData"};
    }
    let PM10Risk = "";
    let PM25Risk = "";
    let NO2Risk = "";
    if (siteSensorData.pm10 !== undefined) PM10Risk = getRiskLevel(siteSensorData.pm10, chosenGuideline ==='WHO' ? PM10_THRESHOLDS : DEFRA_PM10_THRESHOLDS);
    if (siteSensorData.pm25 !== undefined) PM25Risk = getRiskLevel(siteSensorData.pm25, chosenGuideline ==='WHO' ? PM25_THRESHOLDS : DEFRA_PM25_THRESHOLDS);
    if (siteSensorData.no2 !== undefined) NO2Risk = getRiskLevel(siteSensorData.no2, chosenGuideline ==='WHO' ? NO2_THRESHOLDS: DEFRA_NO2_THRESHOLDS);

    // if the most recent out of siteSensorData.last_pm10, siteSensorData.last_pm25, siteSensorData.last_no2 is over 1 days old, return noData icon
    const mostRecentDate = Math.max(
        new Date(siteSensorData.created_at ?? "01-01-2000").getTime(),
        new Date(siteSensorData.created_at ?? "01-01-2000").getTime(),
        new Date(siteSensorData.created_at ?? "01-01-2000").getTime()
    );
    const oneDayAgo = new Date().getTime() - 24 * 60 * 60 * 1000;
    if (mostRecentDate < oneDayAgo) {
        return {icon: MARKER_IMAGES.noData, level: "noData"};
    }



    const riskArray = [PM10Risk, PM25Risk, NO2Risk].filter(
        (value) => value !== ""
    );

    if (riskArray.every((level) => level === "low")) {
        return {icon: MARKER_IMAGES.low, level: "low"};
    }
    if (riskArray.every((level) => level === "medium" || level === "low")) {
        return {icon: MARKER_IMAGES.medium, level: "medium"};
    }
    if (riskArray.includes("high")) {
        return {icon: MARKER_IMAGES.high, level: "high"};
    }
    return {icon: MARKER_IMAGES.noData, level: "noData"};
};


const getMode = (array: (string | null)[]) => {
    const frequencyMap: { [key: string]: number } = {};
    let maxFrequency = 0;
    let modes: string[] = [];
    const order = ["noData", "low", "medium", "high"];

    for (const item of array) {
        if (item === null) continue;

        if (!frequencyMap[item]) {
            frequencyMap[item] = 1;
        } else {
            frequencyMap[item]++;
        }

        if (frequencyMap[item] > maxFrequency) {
            maxFrequency = frequencyMap[item];
            modes = [item];
        } else if (frequencyMap[item] === maxFrequency) {
            modes.push(item);
        }
    }

    if (modes.length === 0) return "noData";

    return modes.reduce((highestMode, mode) =>
        order.indexOf(mode) > order.indexOf(highestMode) ? mode : highestMode
    );
};

export function customCalculator(markers: google.maps.Marker[]) {
    const markersRiskLevel = markers.map((m) => {
        return m.get("level");
    });
    const mode = getMode(markersRiskLevel);
    let index = 1;
    switch (mode) {
        case "high":
            index = 3;
            break;
        case "medium":
            index = 2;
            break;
        case "low":
            index = 1;
            break;
        default:
            index = 4;
            break;
    }
    return {
        text: markers.length.toString(),
        index: index,
        title: "",
    };
}

