import React, { useState, useEffect } from 'react';
import { DotLottiePlayer } from "@dotlottie/react-player";
import { Box } from "@mui/material";
// @ts-ignore
import idleAnimation from "/@/assets/animations/idle.lottie";
import thinkingAnimation from "/@/assets/animations/assistant-response-loading.json";

interface AirAwareMascotProps {
    isLoading: boolean;
    isMobile?: boolean;
    style?: React.CSSProperties;
}

export const AirAwareMascotMobile: React.FC<AirAwareMascotProps> = ({ isLoading, isMobile, style }) => {
    const [currentAnimation, setCurrentAnimation] = useState(idleAnimation);
    const [fade, setFade] = useState(false);

    useEffect(() => {
        if (isMobile) {
            setFade(true);

            const timeout = setTimeout(() => {
                setCurrentAnimation(isLoading ? thinkingAnimation : idleAnimation);
                setFade(false);
            }, 250);

            return () => clearTimeout(timeout);
        }
    }, [isLoading, isMobile]);

    const containerStyle = {
        ...style,
        position: isMobile ? 'absolute' as 'absolute' : 'relative' as 'relative',
        width: isMobile ? '100%' : '300px',
        height: isMobile ? '100px' : '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        transform: isMobile ? undefined : 'translate3d(-46px, 86px, 0px)',
    };

    const animationStyle = {
        width: isMobile ? '100%' : '160px',
        height: isMobile ? '90px' : '100%',
        display: 'flex',
        justifyContent: 'center',
        transition: 'opacity 0.5s ease-in-out',
        transform: isMobile ? undefined : 'translate3d(-55px, 90px, 0px)',
        position: isMobile ? 'absolute' as 'absolute' : undefined,
        top: isMobile ? 5 : undefined,
        left: isMobile ? 0 : undefined,
    };


    return (
        <Box sx={containerStyle}>
            <DotLottiePlayer
                style={{
                    ...animationStyle,
                    opacity: fade ? 0 : 1,
                    zIndex: 1,
                }}
                key={currentAnimation.toString()}
                src={currentAnimation}
                autoplay
                loop
            />
            <DotLottiePlayer
                style={{
                    ...animationStyle,
                    opacity: fade ? 1 : 0,
                    zIndex: 2,
                    position: 'absolute',
                    transition: 'opacity 0.2s ease-in-out'
                }}
                key={isLoading ? thinkingAnimation.toString() : idleAnimation.toString()}
                src={isLoading ? thinkingAnimation : idleAnimation}
                autoplay
                loop
            />
        </Box>
    );
};

export default AirAwareMascotMobile;
