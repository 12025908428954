import {useState, useCallback, useEffect} from 'react';
import {
    SensorData,
    AllAveragedAirQualityData,
    PollutantDataEntry
} from '/@/types/mapTypes';
import { applyOffsetToCloseSensors } from '/@/utils/mapUtils';
import { useMapContext } from './mapContext';
import { useSupabase } from "/@/context/supabaseContext";
import { useJsApiLoader } from "@react-google-maps/api";
import { googleLibraries } from "/@/store/constants";
import { supabaseClient } from "/@/renderer/PageShell";
export const useMapData = () => {
    const { isLoaded: isMapLoaded } = useJsApiLoader({
        id: "google-index-script",
        googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API,
        libraries: googleLibraries,
    });
    const [isLoadingSensorData, setIsLoadingSensorData] = useState(false);
    const [sensorData, setSensorData] = useState<SensorData[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const { supabase } = useSupabase();
    const {
        chosenBorough,
        chosenGraphSensor,
        chosenDateRange,
        setAirQualityData,
        chosenGraphInterval,
        chosenGraphMetric,
        chosenMapPinDateRange,
        chosenRadius,
        chosenMapPinInterval,
        chosenMapPinMetric
    } = useMapContext();

    // New helper function to clear air quality data
    const clearAirQualityData = useCallback(() => {
        setAirQualityData({
            pm10: [],
            pm25: [],
            no2: []
        });
    }, [setAirQualityData]);

    // Modified getSensorReadingsByDateRange
    const getSensorReadingsByDateRange = useCallback(async (): Promise<void> => {
        if (!supabaseClient || !chosenDateRange[0] || !chosenDateRange[1] || !chosenGraphSensor) {
            console.log('Missing required parameters for sensor readings');
            clearAirQualityData();
            return;
        }

        try {
            setIsLoadingSensorData(true);
            clearAirQualityData(); // Clear data before fetching new data

            const response = await supabaseClient.functions.invoke(
                "get-historical-air-quality-data",
                {
                    body: {
                        interval: chosenGraphInterval,
                        metric: chosenGraphMetric,
                        date_range: {
                            start_date: chosenDateRange[0].toISOString(),
                            end_date: chosenDateRange[1].toISOString()
                        },
                        radius: 1,
                        sensor_id: chosenGraphSensor.id,
                        group_by: "interval",
                        view: "graph"
                    }
                }
            );

            if (response.error) throw response.error;

            console.log("RECEIVED RESPONSE!!!!!!")

            const averageAirQualityData: AllAveragedAirQualityData = {
                pm10: [],
                pm25: [],
                no2: []
            };

            ['pm25', 'pm10', 'no2'].forEach((pollutant) => {
                const pollutantData = response.data[pollutant];
                if (pollutantData) {
                    const dateRanges = Object.keys(pollutantData);
                    averageAirQualityData[pollutant as keyof AllAveragedAirQualityData] = dateRanges.map(dateRange => ({
                        [dateRange]: pollutantData[dateRange].avg_reading
                    }));
                }
            });

            setAirQualityData(averageAirQualityData);
        } catch (error) {
            console.error('Error fetching sensor readings:', error);
            setError(error as Error);
            clearAirQualityData();
        } finally {
            setIsLoadingSensorData(false);
        }
    }, [chosenDateRange, chosenGraphInterval, chosenGraphMetric, chosenGraphSensor, setAirQualityData, clearAirQualityData]);

    const getMapPins = useCallback(async (useLiveData: boolean): Promise<void> => {
        try {
            if (!supabase) throw new Error('Supabase client not initialized');

            // First attempt RPC call
            const { data: latestData, error: rpcError } = await supabase.rpc(
                "get_latest_readings"
            );

            if (rpcError) {
                console.error('RPC Error:', rpcError);
                throw rpcError;
            }

            if (!latestData || latestData.length === 0) {
                console.warn('No data received from get_latest_readings');
                return;
            }

            // console.log('Raw sensor data:', latestData); // Debug log

            const adjustedSensorData = applyOffsetToCloseSensors(latestData);

            if (useLiveData) {
                const now = new Date();
                const cutOffTime = new Date(now.setHours(now.getHours() - 2));
                const filteredData = adjustedSensorData.filter(
                    (sensor: SensorData) => {
                        const sensorDate = new Date(sensor.created_at as string);
                        return sensorDate > cutOffTime;
                    }
                );
                setSensorData(filteredData);
            } else {
                setSensorData(adjustedSensorData);
            }

        } catch (error) {
            console.error('Error in getMapPins:', error);
            setError(error as Error);
            // Fallback to edge function if RPC fails
            try {
                const response = await supabaseClient.functions.invoke(
                    "get-historical-air-quality-data",
                    {
                        body: {
                            interval: chosenMapPinInterval,
                            metric: chosenMapPinMetric,
                            date_range: chosenMapPinDateRange,
                            radius: chosenRadius,
                            location_string: "London",
                            group_by: "sensor",
                            view: "map"
                        }
                    }
                );

                if (response.error) throw response.error;

                const adjustedSensorData = applyOffsetToCloseSensors(response.data);
                setSensorData(adjustedSensorData);
            } catch (fallbackError) {
                console.error('Fallback error:', fallbackError);
                setError(fallbackError as Error);
            }
        }
    }, [chosenMapPinInterval, chosenMapPinMetric, chosenMapPinDateRange, chosenRadius, supabase]);

    // Modified getAverageReadingsByBorough
    const getAverageReadingsByBorough = useCallback(async (): Promise<void> => {
        if (!supabase || !chosenDateRange[0] || !chosenDateRange[1]) {
            console.error('Missing required parameters for borough readings');
            clearAirQualityData();
            return;
        }

        try {
            setIsLoadingSensorData(true);
            clearAirQualityData(); // Clear data before fetching new data

            const response = await supabaseClient.functions.invoke(
                "get-historical-air-quality-data",
                {
                    body: {
                        interval: chosenGraphInterval,
                        metric: chosenGraphMetric,
                        date_range: {
                            start_date: chosenDateRange[0].toISOString(),
                            end_date: chosenDateRange[1].toISOString()
                        },
                        radius: 1,
                        location_string: chosenBorough,
                        group_by: "interval",
                        view: "graph"
                    }
                }
            );

            if (response.error) {
                throw response.error;
            }

            const averageAirQualityData: AllAveragedAirQualityData = {
                pm10: [],
                pm25: [],
                no2: []
            };

            ['pm25', 'pm10', 'no2'].forEach((pollutant) => {
                const pollutantData = response.data[pollutant];
                if (pollutantData) {
                    const dateRanges = Object.keys(pollutantData);
                    averageAirQualityData[pollutant as keyof AllAveragedAirQualityData] = dateRanges.map(dateRange => ({
                        [dateRange]: pollutantData[dateRange].avg_reading
                    }));
                }
            });

            setAirQualityData(averageAirQualityData);
        } catch (error) {
            console.error('Error fetching borough readings:', error);
            setError(error as Error);
            clearAirQualityData();
        } finally {
            setIsLoadingSensorData(false);
        }
    }, [
        supabase,
        chosenDateRange,
        chosenBorough,
        chosenGraphInterval,
        chosenGraphMetric,
        setAirQualityData,
        clearAirQualityData
    ]);

    return {
        isLoadingSensorData,
        isMapLoaded,
        sensorData,
        fetchAirData: getMapPins,
        getSensorReadingsByDateRange,
        getAverageReadingsByBorough,
        error,
    };
};

export default useMapData;