import React from 'react';
import {Button, Fade, Grid, Skeleton, Typography, useMediaQuery, useTheme} from '@mui/material';

interface Props {
    possibleUserMessages: Array<string> | null;
    handleReply: (message: string) => void;
    loading: boolean;
}

const PossibleUserMessages: React.FC<Props> = ({possibleUserMessages, handleReply, loading}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const colors = [
        "linear-gradient(135deg, #5FBCFF 0%, #0396FF 100%)",
        "linear-gradient(135deg, #FF9A68 0%, #EA5455 100%)",
        "linear-gradient(135deg, #D690FF 0%, #9F44D3 100%)",
    ];

    const SkeletonButton = () => (
        <Grid item xs={4}>
            <Skeleton variant="rectangular" height={50} sx={{borderRadius: '10px', margin: '20px', width: '90%'}}/>
        </Grid>
    );



    return (
        <>
            {loading
                ? Array.from(new Array(3)).map((_, index) =>
                    <Fade key={index} in={loading} unmountOnExit timeout={1000}><SkeletonButton/></Fade>
                )
                : (
                    possibleUserMessages &&
                    possibleUserMessages.map((message, index) => (
                        <Fade key={index} in={!loading} timeout={1000}>
                            <Grid sx={{padding: '10px', pt: "0px"}} item xs={isMobile ? 10 : 6}>
                                <Button
                                    aria-label={'Possible user message'}
                                    variant="contained"
                                    sx={{
                                        textTransform: 'none',
                                        padding: '10px',
                                        borderRadius: '10px',
                                        color: 'white',
                                        width: '100%',
                                        background: colors[index % colors.length],
                                        fontSize: '1.2rem',
                                    }}
                                    onClick={() => handleReply(message)}
                                >
                                    <Typography variant="body2" fontWeight={'700'}>{message}</Typography>
                                </Button>
                            </Grid>
                        </Fade>
                    ))
                )}
        </>
    );
};

export default PossibleUserMessages;