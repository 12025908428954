import React from 'react'
import {hotjar} from "react-hotjar";
import {CssBaseline} from "@mui/material";
import {createClient} from "@supabase/supabase-js";
import {PageContextProvider} from './usePageContext'
import {Provider} from "react-redux";
import type {PageContext} from 'vike/types'
import MainLayout from '../components/layout/MainLayout'
import {store} from "/@/store";
import {LicenseInfo} from '@mui/x-license-pro';
import {CustomThemeProvider, useThemeContext} from "/@/context/themeContext";
import {SessionContextProvider} from "@supabase/auth-helpers-react";
import {SupabaseProvider} from "/@/context/supabaseContext";
import {ChatProvider} from "/@/context/chatContext";
import "shepherd.js/dist/css/shepherd.css";
import "/@/components/shepherdTour/shepherdStyles.css";
import {EmotionCache} from "@emotion/cache";
import ReactGA from "react-ga4";
import {CacheProvider} from '@emotion/react';
import {IdleProvider} from '/@/context/idleContext';
import {TrackingProvider} from "/@/context/trackingContext";

export {PageShell}

export const supabaseClient = createClient(
    import.meta.env.VITE_SUPABASE_URL,
    import.meta.env.VITE_SUPABASE_ANON_KEY
);

ReactGA.initialize("G-RRMRHTMJFT");
LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_PRO_KEY);
const mainLayouts = ["/login", "/signup", "/onboarding", "/reset-password", '/update-password', '/privacy-notice', '/terms-and-conditions']

function PageShell({pageContext, children, emotionCache}: {
    pageContext: PageContext;
    children: React.ReactNode,
    emotionCache: EmotionCache
}) {
    const useMainLayout = !mainLayouts.includes(pageContext.urlPathname || '');
    const {} = useThemeContext();
    if (typeof window !== 'undefined') {
        // @ts-ignore
        hotjar.initialize(3662556, 6);
    }

    return (
        // <React.StrictMode>
            <CacheProvider value={emotionCache}>
                <PageContextProvider pageContext={pageContext}>
                    <Provider store={store}>
                        <CssBaseline/>
                        <CustomThemeProvider>
                            <SessionContextProvider supabaseClient={supabaseClient}>
                                <SupabaseProvider>
                                    <TrackingProvider>
                                        <IdleProvider>
                                            {useMainLayout ? (
                                                <ChatProvider>
                                                    <MainLayout>{children}</MainLayout>
                                                </ChatProvider>
                                            ) : (
                                                <>{children}</>
                                            )}
                                        </IdleProvider>
                                    </TrackingProvider>
                                </SupabaseProvider>
                            </SessionContextProvider>
                        </CustomThemeProvider>
                    </Provider>
                </PageContextProvider>
            </CacheProvider>
        // </React.StrictMode>
    )
}