import React from "react";
import { ClickAwayListener, Link as MuiLink, Popover, Typography, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "/@/types/assistantTypes";

export interface QuoteLink {
    label: string;
    organisation: string;
    position: number;
    quoteId: string;
    title: string;
    url: string;
}

interface ChatHoverCardProps {
    link: Link;
}

const StyledLink = styled(MuiLink)(({ theme }) => ({
    font: "inherit",
    textDecorationColor: "rgba(255, 255, 255, 0.4)",
    display: "inline-block",
    padding: "0 0.1em",
    margin: "0 0",
    borderRadius: "5px",
    backgroundColor: "#dde1ff",
    color: "#333",
    textDecoration: "none",
    fontWeight: 500,
    fontSize: "0.7em",
    textAlign: "center",
    minWidth: "1.6em",
    cursor: "pointer",
    '&:hover': {
        backgroundColor: "#c2d8f6",
    },
}));

const ChatHoverCard: React.FC<ChatHoverCardProps> = ({ link }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
        event.preventDefault();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);

    return (
        <ClickAwayListener onClickAway={handlePopoverClose}>
            <span key={link.quoteId}>
                <StyledLink
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={!isMobile ? handlePopoverOpen : undefined}
                    onMouseLeave={!isMobile ? handlePopoverClose : undefined}
                    onClick={isMobile ? handleClick : undefined}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {link.label.replace(/[\[\]]/g, '')}
                </StyledLink>
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: "none",
                        borderRadius: "10px",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography sx={{ p: 1, maxWidth: 400 }}>
                        <Typography variant="h6">{link.title}</Typography>
                        <Typography variant="body2">
                            Organisation: {link.organisation}
                        </Typography>
                        <Typography variant="caption">Quote: {link.quote ?? ""}</Typography>
                    </Typography>
                </Popover>
            </span>
        </ClickAwayListener>
    );
};

export default ChatHoverCard;
