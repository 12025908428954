import {
    Box,
    Button,
    Dialog,
    Fade,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useState} from "react";
import markerGreen from "/@/assets/markers/green-sensor.svg";
import markerBlue from "/@/assets/markers/colour-blind-blue-sensor.svg";
import markerMedium from "/@/assets/markers/orange-sensor.svg";
import markerHigh from "/@/assets/markers/purple-sensor.svg";
import {useTranslation} from "react-i18next";
import {useThemeContext} from "/@/context/themeContext";



const MapKey: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const {isColorBlindMode} = useThemeContext();
    const {t} = useTranslation();
    const [showMapKey, setShowMapKey] = useState<boolean>(false);

    const [markerLow, setMarkerLow] = useState<string>(
        isColorBlindMode ? markerBlue : markerGreen
    );

    const markerDescription = [
        {
            marker: markerLow,
            pollution: t("pollution_low"),
            text: t("pollution_level_description_low")
        },
        {
            marker: markerMedium,
            pollution: t("pollution_medium"),
            text: t("pollution_level_description_medium")
        },
        {
            marker: markerHigh,
            pollution: t("pollution_high"),
            text: t("pollution_level_description_high")
        },
    ];

    const generateKeyTable = () => {
        return (
            <TableContainer
                sx={{
                    height: "100%",
                    width: {md: 400},
                    bgcolor: 'white',
                    borderRadius: '5px',
                    zIndex: 2,
                }}
                id='tour-key-legend'
            >
                <Grid container display='flex' alignItems='end' textAlign='end'>
                    <Grid xs={12}>
                <IconButton
                    aria-label={'Close button'}
                    size="small"
                    sx={{

                        top: 2,
                        justifyContent: "flex-end",
                        color: 'black',
                    }}
                    onClick={() => setShowMapKey(false)}
                >
                    <CloseIcon/>
                </IconButton>
                    </Grid>
                </Grid>
                <Table size="small">
                    <TableBody>
                        {markerDescription.map((marker) => {
                            return (
                                <TableRow key={marker.pollution}>
                                    <TableCell align="center" sx={{borderBottom: "none"}}>
                                        <Box
                                            sx={{
                                                height: "30px",
                                                width: "30px",
                                                ml: "auto",
                                                mr: "auto",
                                            }}
                                        >
                                            <img src={marker.marker} height="30px" width="30px" alt={marker.marker}/>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={{borderBottom: "none"}}>
                                        <Typography variant="body2">
                                            {marker.text}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <>
            {!isMobile && showMapKey &&(
                <Fade in={showMapKey} timeout={500}>
                    <Box
                        sx={{
                            width: {xs: 250, md: 400},
                            borderRadius: '5px',
                            position: "absolute",
                            bottom: 10,
                            right: {xs: 200, md: 400},
                            zIndex: 2,
                            minWidth: '20px',
                            maxWidth: '20px',
                        }}
                    >
                        {generateKeyTable()}
                    </Box>
                </Fade>
            )}
            {isMobile && showMapKey && (
                <Dialog
                    open={showMapKey}
                    onClose={() => setShowMapKey(false)}
                    sx={{
                        "& .MuiPaper-root": {
                            bgcolor: theme.palette.secondary.light,
                            borderRadius: '5px',
                            maxWidth: 370,
                        },
                    }}
                >
                    {generateKeyTable()}
                </Dialog>
            )}
            <Button
                color="warning"
                variant="contained"
                sx={{
                    padding: '0px', // Removing button padding
                    minWidth: '0px', // Allowing the button to shrink to fit its contents
                    position: "absolute",
                    bottom: 110,
                    right: 10,
                    borderRadius: '5px',
                    border: "1px solid",
                }}
                id='tour-key-legend'
                aria-label={'Map key'}
                onClick={() => setShowMapKey(true)}
            >
                <span style={{ padding: '6px 8px', color: 'black' }}><Typography variant="button">{t("show_key")}</Typography></span>
            </Button>
        </>
    );
};

export default MapKey;
