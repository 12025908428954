import React, {useEffect, useState} from "react";
import {Box, Dialog,} from "@mui/material";
import sizeConfigs from "/@/configs/sizeConfigs";
import Sidebar from "./Sidebar";
import appRoutes from "/@/routes/appRoutes";
import AppBottomNavigation from "/@/components/layout/AppBottomNavigation";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {styled} from "@mui/material/styles";
import AirAwareChatModal from "/@/components/chat/AirAwareChatModal";
import {useSupabase} from "/@/context/supabaseContext";
import {hotjar} from "react-hotjar";

const PageContent = styled(Box)(({theme}) => ({
    display: 'flex',
}));

const NavContainer = styled(Box)(({theme}) => ({
    width: sizeConfigs.sidebar.width, // Assuming sizeConfigs is defined and valid
    flexShrink: 0,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
        display: 'none'
    },
}));

const MainContainer = styled(Box)(({theme}) => ({
    flexGrow: 1,
    padding: 0,
    width: `calc(100% - ${sizeConfigs.sidebar.width})`, // Adjust if sizeConfigs is dynamic
    minHeight: '100vh',
    backgroundColor: 'white',
}));

const ContentContainer = styled(Box)(({theme}) => ({
    padding: 0,
    margin: 0,
    borderRadius: '0 0 0 50px',
    backgroundColor: theme.palette.secondary.main,
    maxHeight: '100vh',
    minHeight: '100vh',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
        maxHeight: 'calc(100vh - 90px)',
        minHeight: 'calc(100vh - 90px)',
        borderRadius: 0
    },
}));

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


export default function MainLayout({children}: { children: React.ReactNode }) {
    const {user} = useSupabase();

    const [value, setValue] = useState(0);

    useEffect(() => {
        const currentIndex = appRoutes.findIndex((route) => route.path === window.location.pathname);
        setValue(currentIndex);
    }, []);

    useEffect(() => {
        if (user && typeof window !== 'undefined') {
            hotjar.identify(user.id, {email: user.email});
        }
    }, [user]);

    return (
        <PageContent id="page-content">
            <NavContainer component="nav">
                <Sidebar/>
            </NavContainer>
            <MainContainer component="main">
                <AirAwareChatModal/>
                <ContentContainer>
                    {children}
                </ContentContainer>
                <AppBottomNavigation setValue={setValue} value={value}/>
            </MainContainer>
        </PageContent>
    );
};
