import React, {createContext, useContext, useEffect, useRef} from 'react';
import ReactGA from 'react-ga4';
import {useSupabase} from '/@/context/supabaseContext';
import {boroughToIdMap} from '/@/constants/accountList';
import {usePageContext} from "/@/renderer/usePageContext";

const TrackingContext = createContext<void | null>(null);

export const useTracking = () => useContext(TrackingContext);

type Props = {
    children: React.ReactNode;
};

export const TrackingProvider = ({children}: Props) => {
    const {user} = useSupabase();
    const pageContext = usePageContext();
    const {urlPathname} = pageContext
    const startTimeRef = useRef(Date.now());
    const visitedPagesRef = useRef(new Set());


    useEffect(() => {
        if (user?.id && boroughToIdMap.hasOwnProperty(user.id)) {
            // console.log('Tracking user', user.id);
            const borough = boroughToIdMap[user.id];
            ReactGA.set({ dimension1: user.id, dimension2: borough });

            const currentPath = pageContext.urlPathname;
            if (!visitedPagesRef.current.has(currentPath)) {
                visitedPagesRef.current.add(currentPath);
                ReactGA.event({
                    category: 'Page Visits',
                    action: currentPath,
                    label: borough,
                    nonInteraction: true,
                });
            }

            return () => {
                const endTime = Date.now();
                const usageTime = endTime - startTimeRef.current;
                ReactGA.event({
                    category: 'Usage Time',
                    action: 'Time Spent',
                    value: usageTime,
                    label: borough,
                    nonInteraction: true,
                });
            };
        }
    }, [user, urlPathname]);

    return (
        <TrackingContext.Provider value={null}>{children}</TrackingContext.Provider>
    );
};