import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {useIdleTimer} from 'react-idle-timer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";
import {navigate} from "vike/client/router";
import {deleteAccountBlacklist} from "/@/constants/accountList";
import {useSupabase} from "/@/context/supabaseContext";

interface ContextType {}

const IdleContext = createContext<ContextType>({});
type Props = {
    children: React.ReactNode;
};
export const useIdle = () => useContext(IdleContext);
export const IdleProvider = ({ children } : Props) => {
    const { t } = useTranslation();
    const { user } = useSupabase(); // Consume the user from SupabaseProvider
    const [open, setOpen] = useState(false);
    const [idleActionTriggered, setIdleActionTriggered] = useState(false); // Track whether idle action has been triggered
    const timeout = 300000; // 5 minutes
    const promptBeforeIdle = 10000; // 10 seconds before idle to show prompt

    useEffect(() => {
        const isIdleActionTriggered = localStorage.getItem('idleActionTriggered') === 'true';
        setIdleActionTriggered(isIdleActionTriggered);
    }, []);

    const onIdle = useCallback(() => {
        if (!idleActionTriggered) {
            setOpen(false); // Close any open dialog
            setIdleActionTriggered(true)
            localStorage.setItem('idleActionTriggered', 'true');
            navigate('/')
        }
    }, []);

    const onActive = useCallback(() => {
        setOpen(false);
        setIdleActionTriggered(false); // Reset the idle action triggered state
        localStorage.setItem('idleActionTriggered', 'false');
    }, []);

    const onPrompt = useCallback(() => {
        if(!idleActionTriggered) setOpen(true); // Open the dialog when about to become idle
    }, []);

    const { activate, pause } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500,
    });

    useEffect(() => {
        if (user && deleteAccountBlacklist.includes(user.id)) {
            activate(); // Start the timer if the user is not in the blacklist
        } else {
            // activate(); // Start the timer if the user is not in the blacklist
            pause(); // Pause the timer if the user is in the blacklist or not logged in
        }
    }, [user, activate, pause]); // Depend on user state

    const handleStillHere = () => {
        activate();
        setOpen(false); // Close the dialog
        setIdleActionTriggered(false); // Reset the idle action triggered state
        localStorage.setItem('idleActionTriggered', 'false');
    };

    return (
        <IdleContext.Provider value={{}}>
            {children}
            <Dialog
                sx={{ zIndex: 99999 }}
                open={open}
                onClose={(event, reason) => {
                    if (reason && reason === "backdropClick")
                        return;
                    else setOpen(false);
                }}
                aria-labelledby="idle-dialog-title"
                aria-describedby="idle-dialog-description"
            >
                <DialogTitle id="idle-dialog-title">{t('are_you_still_here')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="idle-dialog-description">
                        {t('resetting_in', { remaining: '10' })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="warning" variant="contained" onClick={handleStillHere}>{t('still_here')}</Button>
                </DialogActions>
            </Dialog>
        </IdleContext.Provider>
    );
};