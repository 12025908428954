import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {NewsArticle} from "/@/components/community/NewsCarouselCard";
import {supabaseClient} from "/@/renderer/PageShell";

export interface NewsState {
    articles: NewsArticle[];
    articlesStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    loadingArticleStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    articleSummary: "",
    articleSummaryStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    // error: null | string;
}

const initialState: NewsState = {
    articles: [],
    articlesStatus: 'idle',
    loadingArticleStatus: 'idle',
    articleSummary: "",
    articleSummaryStatus: 'idle',
};

export const fetchAllArticles = createAsyncThunk(
    'articles/fetchAll',
    async (messageData: { "organisations": [], "language": string }) => {
        const {data, error} = await supabaseClient.functions.invoke('get-local-news', {
            body: {language_code: messageData.language}
        })
        if (error) throw error;
        return data;
    }
);


export const getArticleDetails = createAsyncThunk(
    'articles/fetchDetails',
    async (messageData: {articleId: string, language: string}) => {
        const {data, error} = await supabaseClient.functions.invoke('get-local-news-article', {
            body: {id: messageData.articleId, language_code: messageData.language}
        });
        if (error) return "";
        return data.summary;
    }
);


// Redux slice setup
export const newsApiSlice = createSlice({
    name: 'newsApiSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllArticles.pending, (state) => {
                state.articlesStatus = 'loading';
            })
            .addCase(fetchAllArticles.fulfilled, (state, action) => {
                state.articles = action.payload;
                state.articlesStatus = 'succeeded'
            })
            .addCase(getArticleDetails.pending, (state) => {
                state.articleSummaryStatus = 'loading';
            })
            .addCase(getArticleDetails.fulfilled, (state, action) => {
                state.articleSummary = action.payload;
                state.articleSummaryStatus = 'succeeded';
            })
            .addCase(getArticleDetails.rejected, (state) => {
                state.articleSummaryStatus = 'failed';
            });
    },
});

export const {} = newsApiSlice.actions;

export default newsApiSlice.reducer