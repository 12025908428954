import React from 'react'
import { createContext, useState, useContext } from "react";

interface ContextType {
    message: string;
    setMessage: (msg: string) => void;
    open: boolean;
    toggleOpen: () => void;
}

interface Props {
    children: React.ReactNode;
}

// provide default values
export const ChatContext = createContext<ContextType>({
    message: '',
    setMessage: () => {},
    open: false,
    toggleOpen: () => {},
});

// create a provider component
export const ChatProvider = ({ children } : Props) => {
    const [message, setMessage] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);

    const toggleOpen = () => setOpen(!open);

    return (
        <ChatContext.Provider value={{ message, setMessage, open, toggleOpen }}>
            {children}
        </ChatContext.Provider>
    );
};


// custom hook to use the context
export const useChatContext = () => useContext(ChatContext);