import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {supabaseClient} from "/@/renderer/PageShell";


export type LinkType = {
    url: string;
    label: string;
    position: number;
    quoteId: string;
    title: string;
    organisation: string;
};

type ParagraphType = {
    text: string;
    links: LinkType[];
    embed?: string;
    title?: boolean;
    list?: boolean;
};

export type QuoteType = {
    id: string;
    text: string;
};

type DocumentType = {
    title: string;
    url: string;
    quotes: QuoteType[];
};

type SourceType = {
    name: string;
    documents: DocumentType[];
};

export type ArticleType = {
    imageUrl: string;
    title: string;
    article_id: string;
    id?: string;
    type?: 'text' | 'image' | 'embed'
};

export type DetailedArticle = {
    paragraphs: ParagraphType[];
    article_id: string;
    id?: string;
    references: {
        title: string;
        sources: SourceType[];
    };
    type: 'text' | 'image' | 'embed'
}

export type InfoGraphicsType = {
    title: string;
    imageUrl: string;
}
export type InfoHubVideoType = {
    title: string;
    youtubeUrl: string;
}


export interface InfoHubState {
    infoHubArticles: Record<string, ArticleType[]>;
    detailedArticle: DetailedArticle | null;
    selectedArticle: ArticleType | null;
    infoGraphics: InfoGraphicsType[];
    infoHubVideos: InfoHubVideoType[];
    status: "idle" | "loading" | "succeeded" | "failed";
    gettingArticleStatus: "idle" | "loading" | "succeeded" | "failed";
    gettingInfoGraphicsStatus: "idle" | "loading" | "succeeded" | "failed";
    gettingVideoStatus: "idle" | "loading" | "succeeded" | "failed";
    dialogueOpen: boolean;
}

const initialState: InfoHubState = {
    infoHubArticles: {},
    selectedArticle: null,
    status: "idle",
    detailedArticle: {
        paragraphs: [],
        article_id: "",
        references: {title: '', sources: []},
        type: 'text'
    },
    gettingArticleStatus: "idle",
    dialogueOpen: false,
    infoGraphics: [],
    infoHubVideos: [],
    gettingInfoGraphicsStatus: "idle",
    gettingVideoStatus: "idle"
};

export const fetchInfoHubCoverArticles = createAsyncThunk(
    'infoHub/fetchCoverArticles',
    async (languageCode: string, thunkAPI) => {
        try {
            const response = await supabaseClient.functions.invoke('get-info-hub-covers', {
                body: {language_code: languageCode}
            });
            return response.data.articles;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error});
        }
    }
);

export const fetchInfoHubDetailedArticle = createAsyncThunk(
    'infoHub/fetchInfoHubDetailedArticle',
    async ({languageCode, articleId}: { languageCode: string, articleId: string | number }, thunkAPI) => {
        try {
            const response = await supabaseClient.functions.invoke('get-info-hub-article', {
                body: {language_code: languageCode, article_id: articleId}
            });
            return response.data.article;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error});
        }
    }
);

export const fetchInfoHubInfoGraphics = createAsyncThunk(
    'infoHub/fetchInfoGraphics',
    async (languageCode: string, thunkAPI) => {
        try {
            const response = await supabaseClient.functions.invoke('get-infographics', {
                body: {language_code: languageCode}
            });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error});
        }
    }
);

export const fetchInfoHubInfoVideos = createAsyncThunk(
    'infoHub/fetchInfoVideos',
    async (languageCode: string, thunkAPI) => {
        try {
            const response = await supabaseClient.functions.invoke('get-videos', {
                body: {language_code: languageCode}
            });
            return response.data;
        } catch (error) {
            console.log('error')
            return thunkAPI.rejectWithValue({error: error});
        }
    }
);

export const infoHubSlice = createSlice({
    name: 'infoHub',
    initialState,
    reducers: {
        clearSelectedArticle: (state) => {
            state.gettingArticleStatus = "idle";
            state.detailedArticle = null;
            state.selectedArticle = null;
            state.dialogueOpen = false;
        },
        setSelectedArticle: (state, action) => {
            state.selectedArticle = action.payload;
            state.dialogueOpen = true;
        },
        setDialogueOpen: (state, action) => {
            state.dialogueOpen = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchInfoHubCoverArticles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchInfoHubCoverArticles.fulfilled, (state, action) => {
                state.infoHubArticles = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchInfoHubCoverArticles.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(fetchInfoHubDetailedArticle.pending, (state) => {
                state.gettingArticleStatus = 'loading';
            })
            .addCase(fetchInfoHubDetailedArticle.fulfilled, (state, action) => {
                state.detailedArticle = action.payload;
                state.gettingArticleStatus = 'succeeded';
            })
            .addCase(fetchInfoHubDetailedArticle.rejected, (state) => {
                state.gettingArticleStatus = 'failed';
            })
            .addCase(fetchInfoHubInfoGraphics.pending, (state) => {
                state.gettingInfoGraphicsStatus = 'loading';
            })
            .addCase(fetchInfoHubInfoGraphics.fulfilled, (state, action) => {
                state.infoGraphics = action.payload;
                state.gettingInfoGraphicsStatus = 'succeeded';
            })
            .addCase(fetchInfoHubInfoGraphics.rejected, (state) => {
                state.gettingInfoGraphicsStatus = 'failed';
            })
            .addCase(fetchInfoHubInfoVideos.pending, (state) => {
                state.gettingVideoStatus = 'loading';
            })
            .addCase(fetchInfoHubInfoVideos.fulfilled, (state, action) => {
                state.infoHubVideos = action.payload;
                state.gettingVideoStatus = 'succeeded';
            })
            .addCase(fetchInfoHubInfoVideos.rejected, (state) => {
                state.gettingVideoStatus = 'failed';
            })
    },
});

export const {clearSelectedArticle, setSelectedArticle, setDialogueOpen} = infoHubSlice.actions;


export default infoHubSlice.reducer;
