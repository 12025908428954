// https://vike.dev/onRenderClient
import createEmotionCache from "/@/mui/emotion";

export {onRenderClient}

import './css/index.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {PageShell} from './PageShell'
import {getPageTitle} from './getPageTitle'
import type {OnRenderClientAsync} from 'vike/types'
import {I18nextProvider} from "react-i18next";
import createCache from '@emotion/cache'
import {CacheProvider} from '@emotion/react';

import i18n from "./i18n";

let root: ReactDOM.Root
const onRenderClient: OnRenderClientAsync = async (pageContext): ReturnType<OnRenderClientAsync> => {
    const {Page, pageProps} = pageContext
    const nonceElement = document.querySelector('meta[name="csp-nonce"]');
    const nonce : string = nonceElement ? nonceElement.getAttribute('content') ?? '' : '';

    // Create the Emotion cache with the nonce
    const emotionCache = createEmotionCache(nonce)

    const page = (
        <PageShell pageContext={pageContext} emotionCache={emotionCache}>
            <I18nextProvider i18n={i18n}>
                {Page && <Page {...pageProps} />}
            </I18nextProvider>
        </PageShell>
    )
    const container = document.getElementById('page-view')!

    if (pageContext.isHydration) {
        root = ReactDOM.hydrateRoot(container, page)
    } else {
        if (!root) {
            root = ReactDOM.createRoot(container)
        }
        root.render(page)
    }
    document.title = getPageTitle(pageContext)
}
