import {
    addNewMessage,
    addStreamedParagraph, finaliseChat,
    loadingPossibleUserMessages,
    stopLoadingBubble,
    updatePossibleUserMessages
} from "/@/store/slices/airAwareAssistantSlice";
import {supabaseClient} from "/@/renderer/PageShell";
import {ChatMessage, followUpQuestionsPrompt, followUpSchema, Message, MessageElement} from "/@/types/assistantTypes";
import {AppDispatch} from "/@/store";

const blockListMessages = ["I'm sorry but I can't assist with that request. However, I'm here to answer any questions you might have about air quality.", "I'm sorry, something went wrong. Please try again or let me know if you have any other air quality questions I can help with."]

export const generatePossibleMessages = async (
    languageCode: string,
    messages: Message[] = [],
    dispatch: AppDispatch
) => {
    const followUpQuestionsMessage: Message = createMessage(
        "system",
        followUpQuestionsPrompt
    );

    let openAiMessages: Message[] = [];
    if (messages.length > 0) {
        openAiMessages = messages;
    }

    openAiMessages.push(followUpQuestionsMessage);

    const followUpMessagesResponse = await callOpenAi(
        openAiMessages,
        followUpSchema,
        "FollowUp"
    );

    const translatedResponsePromises = followUpMessagesResponse[
        "follow_up_messages"
        ].map((message: string) => translateText(message, languageCode));

    const translatedResponses: string[] = await Promise.all(
        translatedResponsePromises
    );

    dispatch(
        updatePossibleUserMessages(
            translatedResponses.filter((message) => message !== "")
        )
    );
    dispatch(stopLoadingBubble())
};

const checkIfContentViolatesBlockList = (content: string): boolean => {
    return blockListMessages.includes(content);
}

export const messageAssistant = async (
    chatHistory: ChatMessage[],
    newMessage: string,
    dispatch: AppDispatch,
    chatId: string,
    languageCode = "en",
    sources = {},
    maxChatMessages = 5
) => {
    try {
        const mostRecentMessages = [...chatHistory]

        mostRecentMessages.push(formatMessage(newMessage))

        const {data, error} = await supabaseClient.from('chat_messages').insert({
            chat_id: chatId,
            chat_history: mostRecentMessages,
            language_code: languageCode,
            sources: sources,
            child_friendly_mode: false,
        }).select("message_id").single()
        const messageId = data!.message_id

        const eventSource = new EventSource(`https://wcwtqdqbuirrfgdctwwm.supabase.co/functions/v1/assistant-stream-v1-2/${messageId}`)

        // create a set of strings which will store ids of chat messages
        const chatMessageIds = new Set<string>()

        eventSource.addEventListener('message', (event: any) => {
            const data: MessageElement = JSON.parse(event.data)
            if ((data.content.trim() || data.type === 'newline') && !chatMessageIds.has(data.id)) {
                chatMessageIds.add(data.id)
                dispatch(addStreamedParagraph({message: data}))
            }
        })

        eventSource.addEventListener('error', async (error) => {

            // if only one message is sent, then we can assume that the message was blocked
            if (mostRecentMessages.length === 1) {
                const translatedFailedMessage = await translateText(
                    "Hmm, something went wrong with that request, please try again.",
                    languageCode
                );
                dispatch(addNewMessage(formatMessage(translatedFailedMessage, "assistant")));
                dispatch(stopLoadingBubble());
                await generatePossibleMessages(languageCode, [], dispatch);
            }

            eventSource.close()
            dispatch(loadingPossibleUserMessages());
            const formattedMessage: Message = {role: 'assistant', content: ""}
            await generatePossibleMessages(languageCode, [formattedMessage], dispatch);
            dispatch(finaliseChat())
            console.error(error)
        })
        return;
    } catch (error) {
        console.log(error);
        const translatedFailedMessage = await translateText(
            "I'm sorry, something went wrong when giving my answer. Please try again.",
            languageCode
        );
        dispatch(addNewMessage(formatMessage(translatedFailedMessage, "assistant")));
        dispatch(stopLoadingBubble());
        await generatePossibleMessages(languageCode, [], dispatch);
    }
};


export const replaceVariables = (input: string, variables: { [key: string]: string }): string => {
    return input.replace(/{(.*?)}/g, (_, g1) => variables[g1]);
}

export const createMessage = (role: 'system' | 'assistant' | 'user', content: string, variables: {
    [key: string]: string
} = {}): Message => {
    let replacedContent = replaceVariables(content, variables);
    return {role, content: replacedContent};
}


export const translateText = async (text: string, languageCode: string = "en-gb"): Promise<string> => {
    const edgeFunctionName = "translations";
    const response = await supabaseClient.functions.invoke(edgeFunctionName, {
        body: {
            text,
            targetLang: languageCode
        }
    });
    return response.data;
}


export const formatMessage = (message: string, sender: "user" | "assistant" = "user"): ChatMessage => {
    return {
        sender: sender,
        structured_message: {
            messageElements:
                [{
                    content: message,
                    type: 'text',
                    links: [],
                    isFinalMessage: false,
                    id: "0",
                    index: 0
                }],
        },
    };
}

export const callOpenAi = async (messages: Message[], functionSchema: any = {}, schemaName: string = "") => {
    try {
        const response = await supabaseClient.functions.invoke('azure-openai-search', {
            body: {messages, functionSchema, schemaName}
        })
        return response.data;
    } catch (error) {
        console.error(error);
        return {}
    }
};

