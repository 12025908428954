import React, {useState, useEffect} from "react";
import {useSupabase} from "./supabaseContext";
import {useTranslation} from "react-i18next";


export type Borough = "Hackney" | "Newham" | "City of London" | "Tower Hamlets";
export type Transport =  "Walking, Wheeling" | "Cycling" | "Trains" | "Buses" | "Cars";
export type Interest =
    | "Change my habits to reduce my own pollution"
    | "Reducing my exposure to air pollution"
    | "Learn more in-depth about air pollution"
    | "Get information about what my council is doing"
    | "Join local community events"
    | "General air quality news";
export type Accessibility =
    | "Hearing loss"
    | "Vision loss"
    | "Limited mobility"

// Removed the OptionsList from here

interface OnboardingContextType {
    boroughs: Borough[];
    borough: Borough | undefined;
    setBorough: React.Dispatch<React.SetStateAction<Borough | undefined>>;
    transportOptions: Transport[];
    transport: Transport[];
    setTransport: React.Dispatch<React.SetStateAction<Transport[]>>;
    interestOptions: Interest[];
    interest: Interest[];
    setInterest: React.Dispatch<React.SetStateAction<Interest[]>>;
    accessibilityOptions: Accessibility[];
    accessibility: Accessibility[];
    setAccessibility: React.Dispatch<React.SetStateAction<Accessibility[]>>;
}

const OnboardingContext = React.createContext<OnboardingContextType>({
    boroughs: ["Hackney", "Newham", "City of London", "Tower Hamlets"],
    borough: undefined,
    setBorough: () => {
    },
    transportOptions: [],
    transport: [],
    setTransport: () => {
    },
    interestOptions: [],
    interest: [],
    setInterest: () => {
    },
    accessibilityOptions: [],
    accessibility: [],
    setAccessibility: () => {
    },
});

export const useOnboardingContext = () => React.useContext(OnboardingContext);

interface Props {
    children: React.ReactNode;
}

export const OnboardingProvider = ({children}: Props) => {
    const {user} = useSupabase();
    const { t } = useTranslation();

    const boroughs = t('onboarding.boroughs', {returnObjects: true}) as Borough[];
    const transportOptions = t('onboarding.transportOptions', {returnObjects: true}) as Transport[];
    const accessibilityOptions = t('onboarding.accessibilityOptions', {returnObjects: true}) as Accessibility[];
    const interestOptions = t('onboarding.interestOptions', {returnObjects: true}) as Interest[];
    //
    // const getInitialState = (key: 'council' | 'transport' | 'interest' | 'accessibility', defaultValue: any) => {
    //     const localUserMetadata = localStorage.getItem("userMetadata")
    //         ? JSON.parse(localStorage.getItem("userMetadata") as string)
    //         : null;
    //
    //     if (key === 'council') {
    //         return user && user.metadata?.council
    //             ? user.metadata?.council
    //             : localUserMetadata?.council
    //                 ? localUserMetadata.council
    //                 : defaultValue;
    //     } else if (key === 'transport') {
    //         return user && user.metadata?.transport
    //             ? user.metadata?.transport
    //             : localUserMetadata?.transport
    //                 ? localUserMetadata.transport
    //                 : defaultValue;
    //     } else if (key === 'interest') {
    //         return user && user.metadata?.interest
    //             ? user.metadata?.interest
    //             : localUserMetadata?.interest
    //                 ? localUserMetadata.interest
    //                 : defaultValue;
    //     } else if (key === 'accessibility') {
    //         return user && user.metadata?.accessibility
    //             ? user.metadata?.accessibility
    //             : localUserMetadata?.accessibility
    //                 ? localUserMetadata.accessibility
    //                 : defaultValue;
    //     }
    // }

    const [borough, setBorough] = useState<Borough| undefined>(undefined);
    const [transport, setTransport] = useState<Transport[]>([]);
    const [interest, setInterest] = useState<Interest[]>([]);
    const [accessibility, setAccessibility] = useState<Accessibility[]>([]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const localUserMetadata = localStorage.getItem("userMetadata")
                ? JSON.parse(localStorage.getItem("userMetadata") as string)
                : null;

            setBorough(user && user.metadata?.council
                ? user.metadata?.council
                : localUserMetadata?.council
                    ? localUserMetadata.council
                    : undefined);
            setTransport(user && user.metadata?.transport
                ? user.metadata?.transport
                : localUserMetadata?.transport
                    ? localUserMetadata.transport
                    : []);
            setInterest(user && user.metadata?.interest
                ? user.metadata?.interest
                : localUserMetadata?.interest
                    ? localUserMetadata.interest
                    : []);
            setAccessibility(user && user.metadata?.accessibility
                ? user.metadata?.accessibility
                : localUserMetadata?.accessibility
                    ? localUserMetadata.accessibility
                    : []);
        }
    }, [user]);
    return (
        <OnboardingContext.Provider
            value={{
                boroughs,
                borough,
                setBorough,
                transportOptions,
                transport,
                setTransport,
                interestOptions,
                interest,
                setInterest,
                accessibilityOptions,
                accessibility,
                setAccessibility,
            }}
        >
            {children}
        </OnboardingContext.Provider>
    );
};