import React, {useState} from 'react';
import {Autocomplete, Box, Grid, TextField, Typography} from '@mui/material';
import {useMapContext} from '/@/context/mapContext';
import {useTranslation} from "react-i18next";
import LocationOnIcon from '@mui/icons-material/LocationOn';


interface PlaceOption {
    description: string;
    placeId: string;
}

const MapLocationSettings: React.FC = () => {
    const {mapRef, setMapRef, setLocationCoords, handleBoroughChange} = useMapContext();
    const [inputValue, setInputValue] = useState<string>('');
    const [options, setOptions] = useState<PlaceOption[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const {t} = useTranslation();

    const fetchPlaces = (input: string) => {
        if (window.google && input) {
            const service = new window.google.maps.places.AutocompleteService();
            service.getPlacePredictions({input}, (predictions, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                    setOptions(predictions.map((prediction) => ({
                        description: prediction.description,
                        placeId: prediction.place_id
                    })));
                } else {
                    setOptions([]);
                }
                setLoading(false);
            });
        }
    };

    const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
        setInputValue(newInputValue);
        if (newInputValue.length > 0) {
            setLoading(true);
            fetchPlaces(newInputValue);
        }
    };


    const handleOptionSelect = (event: React.ChangeEvent<{}>, newValue: PlaceOption | undefined) => {
        if (!newValue) return;
        const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
        placesService.getDetails({placeId: newValue.placeId}, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
                if (place.geometry?.location) {
                    mapRef?.panTo({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});
                    mapRef?.setZoom(16);
                    setLocationCoords({
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    });
                }
            }
        });
    };

    return (
        <Autocomplete
            id="google-map-demo"
            sx={{ width: 300, borderRadius: '5px' }}
            filterOptions={(x) => x}
            options={options.map(option => option.description)}
            autoComplete
            includeInputInList
            filterSelectedOptions
            inputValue={inputValue}
            noOptionsText="No locations"
            onChange={(event, value, reason) => {
                const selectedOption = options.find(option => option.description === value);
                handleOptionSelect(event, selectedOption);
            }}
            onInputChange={handleInputChange}
            renderInput={(params) => (
                <TextField {...params} label={t('enter_location')}  variant="outlined"
                           sx={{
                               backgroundColor: 'white',
                               '& .MuiOutlinedInput-notchedOutline': {
                                   borderColor: 'black',
                                   borderWidth: 1,
                                   borderRadius: '5px',
                               },
                               '&:hover .MuiOutlinedInput-notchedOutline': {
                                   borderColor: 'black',
                                   borderWidth: 1,
                                   borderRadius: '5px',
                               },
                           }}  />
            )}
            renderOption={(props, option) => {
                return (
                    <li {...props}>
                        <Grid container alignItems="center" key={option} width={'100%'}>
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                    <Box
                                        component="span"
                                    >
                                        <Typography variant="body2" color="text.primary">
                                            {option}
                                        </Typography>
                                    </Box>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    )

};

export default MapLocationSettings;