import {Box, Typography} from "@mui/material";

export const rangeColors = {
    low: 'rgba(59,168,5,0.5)', // Green
    medium: 'rgba(245,156,90,0.5)', // Orange
    high: 'rgba(179,92,187,0.5)', // Purple
};


export const GREEN = "#00ff6a";
export const ORANGE = "#F28500";
export const PURPLE = "#7851A9";

export type PollutantType = 'PM2.5' | 'PM10' | 'NO2';

export const mapPollutantToKey = {
    "PM2.5": 'pm25',
    "PM10": 'pm10',
    "NO2": 'no2',
}

export const renderNoDataMessage = () => (
    <Box
        width="100%"
        height="200px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top={0}
        left={25}
        zIndex={1}
    >
        <Typography variant="h5">No data available</Typography>
    </Box>
);